import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import DatePickerComponent from "../../components/DatePicker";
import MonthlyReverseCountComponent from "../../components/MonthlyReverseCount";
import TablePaginationLayout from "../../layouts/TablePagination";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useCallback, useEffect, useMemo, useState } from "react";
import TitleComponent from "../../components/Title";
import "./index.css";
import ModalReservationRegister from "../../features/reservation/ModalReservationRegister";
import { useQuery } from "react-query";
import {
  changeStoreCalendar,
  deleteTherapistCalendar,
  downBottomOrderNumber,
  downOrderNumber,
  getCalendarByDate,
  upOrderNumber,
  upTopOrderNumber,
} from "../../api/therapist";
import usePaginate from "../../hooks/usePaginate";
import TooltipOrder from "../../features/reservation/TooltipOrder";
import { getListPaymentMethod } from "../../api/paymentMethod";
import { getListOption } from "../../api/option";
import { getListExtension } from "../../api/extension";
import { getListCourse } from "../../api/course";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { getCountOrders } from "../../api/orders";
import { getListStore } from "../../api/store";
import ModalRestTime from "../../features/reservation/ModalRestTime";
import {
  END_HOUR_TIME,
  START_HOUR_TIME,
  WEB_RESERVATION_TYPE,
} from "../../constants";
import CachedIcon from "@mui/icons-material/Cached";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Tokyo");

const pixelPer10Minutes = 40;
const pixel1Hour = pixelPer10Minutes * 6;

const styleHead = {
  display: "block",
  width: "80px",
  border: "1px solid #e0e0e0",
  textAlign: "center",
  verticalAlign: "middle",
  padding: "16px 4px",
};

const styleSticky = {
  width: "80px",
  display: "flex",
  alignItems: "center",
  position: "sticky",
  zIndex: 5,
  left: 0,
  backgroundColor: "#fff",
  border: "2px solid #e0e0e0",
  padding: "16px 4px",
};

const styleIcon = {
  color: "#ED6C02",
  cursor: "pointer",
};

function ReservationManagement() {
  const [currentTime, setCurrentTime] = useState(dayjs().tz("Asia/Tokyo"));
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [currentDate, setCurrentDate] = useState(dayjs().tz("Asia/Tokyo"));
  const [activeDayIndex, setActiveDayIndex] = useState(null);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [orderSelected, setOrderSelected] = useState({});

  const [isOpenRestTimeModal, setIsOpenRestTimeModal] = useState(false);
  const [restTimeSelected, setRestTimeSelected] = useState({});

  const { page, perPage, total, changePage, setTotal, changePerPage } =
    usePaginate();
  const [calendarList, setCalendarList] = useState([]);
  const [countOrders, setCountOrders] = useState({
    dateMonth: currentDate.format("YYYY-MM"),
    total: null,
  });
  const [countNewOrder, setCountNewOrder] = useState(0);
  const [oldOrderIds, setOldOrderIds] = useState({
    date: currentDate.format("YYYY-MM-DD"),
    ids: null
  });

  const updateCurrentDate = (newDate) => {
    const updatedDate = currentDate.set("date", newDate);
    setCurrentDate(updatedDate);
  };

  const hours = useMemo(() => {
    const hoursArray = [];
    for (let i = START_HOUR_TIME - 1; i < END_HOUR_TIME + 1; i++) {
      const hour = dayjs()
        .tz("Asia/Tokyo")
        .hour(i % 24)
        .minute(0)
        .format("H:mm");
      hoursArray.push(hour);
    }
    return hoursArray;
  }, []);

  const {
    data: dataCalendar,
    refetch: refetchCalendar,
    isLoading: isLoadingCalendar,
  } = useQuery(
    ["calendar_by_date", currentDate],
    () => {
      const payload = {
        date: currentDate.format("YYYY-MM-DD"),
      };
      return getCalendarByDate(payload);
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (currentDate.format("YYYY-MM-DD") !== oldOrderIds.date || oldOrderIds.ids === null) {
          let ids = [];
          data?.data?.data?.forEach((item) => {
            item?.orders?.forEach((order) => {
              ids.push(order.id);
            })
          })
          setOldOrderIds({ date: currentDate.format("YYYY-MM-DD"), ids: ids });
        }
      },
    }
  );

  const {
    data: dataCountOrders,
    refetch: refetchCountOrders,
    isLoading: isLoadingCountOrders,
  } = useQuery(
    ["count_order_by_date", currentDate],
    () => {
      const payload = {
        // start of month
        start_date: currentDate.startOf("month").format("YYYY-MM-DD"),
        // end of month
        end_date: currentDate.endOf("month").format("YYYY-MM-DD"),
      };
      return getCountOrders(payload);
    },
    {
      refetchInterval: 60000, // 1 minute
      keepPreviousData: true,
      onSuccess: (data) => {
        if (currentDate.format("YYYY-MM") !== countOrders.dateMonth) {
          setCountOrders({
            dateMonth: currentDate.format("YYYY-MM"),
            total: data.data,
          });
        }
      },
    }
  );

  const { data: dataStore } = useQuery(
    ["list_store"],
    () => {
      return getListStore({ per_page: 10000 });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCourse } = useQuery(
    ["list_course"],
    () => {
      return getListCourse({ per_page: 10000 });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataPaymentMethod } = useQuery(
    ["list_payment_method"],
    () => {
      return getListPaymentMethod({ per_page: 10000 });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataOption } = useQuery(
    ["list_option"],
    () => {
      return getListOption({ per_page: 10000 });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataExtension } = useQuery(
    ["list_extension"],
    () => {
      return getListExtension({ per_page: 10000 });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const refetchOrders = useCallback(
    async (isClickButtonRefresh = false) => {
      if (isLoadingCountOrders || isLoadingCalendar) return;
      await refetchCalendar();
      await refetchCountOrders();
      setCountOrders({
        dateMonth: currentDate.format("YYYY-MM"),
        total: null,
      });
      if (isClickButtonRefresh) {
        setCurrentDate(dayjs(new Date()));
      }
    },
    [
      refetchCalendar,
      refetchCountOrders,
      isLoadingCountOrders,
      isLoadingCalendar,
      currentDate,
    ]
  );

  useEffect(() => {
    if (dataCountOrders && countOrders.total === null) {
      setCountOrders({
        dateMonth: currentDate.format("YYYY-MM"),
        total: dataCountOrders.data,
      });
    } else if (dataCountOrders && countOrders.total) {
      const newCountOrders = dataCountOrders?.data;
      if (newCountOrders) {
        const newTotal = newCountOrders.reduce(
          (acc, item) => acc + item.total_orders,
          0
        );
        const oldTotal = countOrders.total?.reduce(
          (acc, item) => acc + item.total_orders,
          0
        );
        if (newTotal > oldTotal) {
          setCountNewOrder(newTotal - oldTotal);
        } else {
          setCountNewOrder(0);
        }
      }
    }
  }, [dataCountOrders, countOrders, currentDate]);

  useEffect(() => {
    try {
      if (dataCalendar) {
        const { data, total } = dataCalendar.data;
        setCalendarList(data);
        setTotal(total);
      }
    } catch (error) {}
  }, [dataCalendar, setTotal]);

  useEffect(() => {
    const currentMonth = currentDate.month();
    const currentYear = currentDate.year();
    const daysInCurrentMonth = dayjs(
      `${currentYear}-${currentMonth + 1}-01`
    ).daysInMonth();

    const daysArray = [];
    for (let i = 1; i <= daysInCurrentMonth; i++) {
      const date = dayjs(`${currentYear}-${currentMonth + 1}-${i}`).format(
        "YYYY-MM-DD"
      );
      // find reserve count
      const reserveCount = dataCountOrders?.data?.find(
        (item) => item.date === date
      );
      daysArray.push({
        dayOfWeek: dayjs(date).day(),
        isActive: false,
        reserveCount: reserveCount?.total_orders || 0,
        date: i < 10 ? `0${i}` : `${i}`,
        dateFormat: date,
        isToday: date === dayjs().format("YYYY-MM-DD"),
      });
    }
    setDaysInMonth(daysArray);
  }, [dataCountOrders, currentDate]);

  useEffect(() => {
    const todayIndex = daysInMonth.findIndex(
      (day) => parseInt(day.date) === currentDate.date()
    );
    if (todayIndex !== -1) {
      setActiveDayIndex(todayIndex);
    }
  }, [currentDate, daysInMonth]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs().tz("Asia/Tokyo"));
    }, 1000 * 10);
    return () => clearInterval(interval);
  }, []);

  const renderTimeNow = useMemo(() => {
    if (currentDate.format("YYYY-MM-DD") !== currentTime.format("YYYY-MM-DD"))
      return null;

    const currentHour = currentTime
      .subtract(START_HOUR_TIME - 1, "hour")
      .hour();
    const currentMinute = currentTime.minute();

    const startMinutes = currentHour * 60 + currentMinute;

    const diff = (startMinutes / 10) * pixelPer10Minutes;

    return (
      <div
        style={{
          height: "100%",
          width: "2px",
          position: "absolute",
          zIndex: 6,
          left: `${diff}px`,
          background: "#ff0000",
        }}
      ></div>
    );
  }, [currentTime, currentDate]);

  const renderShift = (calendar) => {
    const startShift = dayjs(
      calendar.calendar_date_start,
      "YYYY-MM-DD HH:mm:ss"
    );
    const endShift = dayjs(calendar.calendar_date_end, "YYYY-MM-DD HH:mm:ss");

    const startMinutes =
      startShift.subtract(START_HOUR_TIME - 1, "hour").hour() * 60 +
      startShift.minute();

    const minutesDifference = endShift.diff(startShift, "minute");

    const totalWidth = (minutesDifference / 10) * pixelPer10Minutes;
    const diff = (startMinutes / 10) * pixelPer10Minutes;

    const count = Math.floor(minutesDifference / 10);

    return (
      <div
        style={{
          height: "100%",
          width: `${totalWidth}px`,
          position: "absolute",
          zIndex: 3,
          left: `${diff}px`,
          background: "#fff",
          display: "flex",
          border: "2px solid #fff",
        }}
      >
        {Array.from({ length: count }, (_, index) => (
          <button
            key={index}
            style={{
              height: "100%",
              width: `${pixelPer10Minutes}px`,
              border: "1px dotted #000",
            }}
            className="hover-cloud"
            onClick={() => {
              const start = startShift.add(10 * index, "minute");
              setOrderSelected({
                therapist_id: calendar.therapist_id,
                calendar_id: calendar.calendar_id,
                store_id: calendar.store_id,
                therapist_name: calendar.name,
                date: currentDate.format("YYYY-MM-DD"),
                date_start: start.format("YYYY-MM-DD HH:mm:ss"),
                date_end: start.add(20, "minute").format("YYYY-MM-DD HH:mm:ss"),
              });
              setIsOpenModal(true);
            }}
          ></button>
        ))}
        {count * pixelPer10Minutes < totalWidth && (
          <div
            style={{
              height: "100%",
              width: `${totalWidth - count * pixelPer10Minutes}px`,
              border: "1px dotted #000",
            }}
          ></div>
        )}
      </div>
    );
  };

  const renderRestTime = (calendar, restTime, index) => {
    const startDate = dayjs(restTime.time_start, "YYYY-MM-DD HH:mm:ss");
    const endDate = dayjs(restTime.time_end, "YYYY-MM-DD HH:mm:ss");
    const startMinutes =
      startDate.subtract(START_HOUR_TIME - 1, "hour").hour() * 60 +
      startDate.minute();
    const minutesDifference = endDate.diff(startDate, "minute");
    const totalWidth = (minutesDifference / 10) * pixelPer10Minutes;
    const diff = (startMinutes / 10) * pixelPer10Minutes;

    return (
      <div
        key={index}
        style={{
          height: "100%",
          width: `${totalWidth}px`,
          position: "absolute",
          zIndex: 4,
          left: `${diff}px`,
          background: "#f5f5f5",
          display: "flex",
          border: "1px solid #f5f5f5",
        }}
      >
        <button
          style={{
            height: "100%",
            width: `${totalWidth}px`,
            border: "1px solid #d32f2f",
            background: "#ffd2d2",
            padding: `${totalWidth <= 30 ? "2px" : "1px 6px"}`,
            fontSize: `${totalWidth <= 30 ? "10px" : "13px"}`,
          }}
          className="hover-cloud"
          onClick={() => {
            setRestTimeSelected({
              id: restTime.id,
              calendar_id: restTime.therapist_calendar_id,
              time_start: restTime.time_start,
              time_end: restTime.time_end,
            });
            setIsOpenRestTimeModal(true);
          }}
        >
          対応不可
        </button>
      </div>
    );
  };

  const renderOrder = useCallback((calendar, order, index) => {
    const startDate = dayjs(order.date_start, "YYYY-MM-DD HH:mm:ss");
    const endDate = dayjs(order.date_end, "YYYY-MM-DD HH:mm:ss");

    const startMinutes =
      startDate.subtract(START_HOUR_TIME - 1, "hour").hour() * 60 +
      startDate.minute();

    const minutesDifference =
      endDate.diff(startDate, "minute") - order.rest_time;

    const totalWidth = (minutesDifference / 10) * pixelPer10Minutes;
    const diff = (startMinutes / 10) * pixelPer10Minutes;

    const restTimeWidth = (order.rest_time / 10) * pixelPer10Minutes;
    const restTimeDiff = (startMinutes / 10) * pixelPer10Minutes + totalWidth;

    return (
      <>
        <Tooltip title={<TooltipOrder order={order} />}>
          <div
            style={{
              height: "100%",
              width: `${totalWidth}px`,
              boxSizing: "border-box",
              position: "absolute",
              zIndex: 5,
              left: `${diff}px`,
              padding: "2px",
            }}
            className="order-item"
          >
            <button
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: !oldOrderIds?.ids?.includes(order.id) && oldOrderIds.date === currentDate.format("YYYY-MM-DD") ? "rgb(255, 224, 199)" : "rgb(225, 245, 235)",
                border: !oldOrderIds?.ids?.includes(order.id) && oldOrderIds.date === currentDate.format("YYYY-MM-DD") ? "2px solid rgb(211, 47, 47)" : "2px solid #85d6ae",
                overflowY: "auto",
              }}
              onClick={() => {
                setOldOrderIds({
                  date: oldOrderIds.date,
                  ids: [...oldOrderIds.ids, order.id]
                });
                setOrderSelected({
                  ...order,
                  therapist_id: calendar.therapist_id,
                  store_id: calendar.store_id,
                  therapist_name: calendar.name,
                  course_payment_method_id: order.course_payment_method_id,
                  extension_id:
                    order.extensions?.length > 0
                      ? order.extensions[0]?.extension_id
                      : "",
                  extension_payment_method_id:
                    order.extensions.length > 0
                      ? order.extensions[0]?.payment_method_id
                      : "",
                  option_id:
                    order.options?.length > 0
                      ? order.options[0]?.option_id
                      : "",
                  option_payment_method_id:
                    order.options.length > 0
                      ? order.options[0]?.payment_method_id
                      : "",
                });
                setIsOpenModal(true);
              }}
            >
              <div
                style={{
                  marginBottom: "3px",
                  overflowX: "hidden",
                  fontSize: "12px",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>{order.customer_name}</span>
                {order?.nomination?.id && (
                  <div
                    style={{
                      color: order?.nomination?.color,
                      background: order?.nomination?.background
                        ? order?.nomination?.background
                        : "none",
                      borderColor: order?.nomination?.background
                        ? order?.nomination?.background
                        : order?.nomination?.color,
                      borderStyle: "solid",
                      borderWidth: "1px",
                      padding: "4px 8px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                      height: "25px",
                    }}
                  >
                    {order?.nomination?.name}
                  </div>
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {order.reservation_type === WEB_RESERVATION_TYPE && (
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "18px",
                      width: "20px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "white",
                      background: "rgba(0, 138, 2, 0.87)",
                      padding: "2px 5px",
                      borderRadius: "50px",
                      marginRight: "5px",
                    }}
                  >
                    W
                  </span>
                )}
                ({order.course?.name})
              </div>
            </button>
          </div>
        </Tooltip>
        {restTimeWidth > 0 && (
          <div
            style={{
              height: "100%",
              width: `${restTimeWidth}px`,
              position: "absolute",
              zIndex: 3,
              left: `${restTimeDiff}px`,
              background: "#f5f5f5",
              display: "flex",
              border: "1px solid #f5f5f5",
            }}
          >
            <button
              style={{
                height: "100%",
                width: `${restTimeWidth}px`,
                background: "rgba(128, 128, 128, 1)",
                border: "1px dashed #000",
              }}
            >
              　
            </button>
          </div>
        )}
      </>
    );
  }, [oldOrderIds, currentDate]);

  const renderOrderInOtherStore = (calendar, order, index) => {
    const startDate = dayjs(order.date_start, "YYYY-MM-DD HH:mm:ss");
    const endDate = dayjs(order.date_end, "YYYY-MM-DD HH:mm:ss");

    const startMinutes =
      startDate.subtract(START_HOUR_TIME - 1, "hour").hour() * 60 +
      startDate.minute();

    const minutesDifference = endDate.diff(startDate, "minute");

    const totalWidth = (minutesDifference / 10) * pixelPer10Minutes;
    const diff = (startMinutes / 10) * pixelPer10Minutes;

    return (
      <div
        style={{
          height: "100%",
          width: `${totalWidth}px`,
          position: "absolute",
          zIndex: 3,
          left: `${diff}px`,
          background: "#f5f5f5",
          display: "flex",
          border: "1px solid #f5f5f5",
        }}
      >
        <button
          style={{
            height: "100%",
            width: `${totalWidth}px`,
            background: "rgba(128, 128, 128, 1)",
            border: "1px dashed #000",
          }}
        >
          　
        </button>
      </div>
    );
  };

  const handleUpOrderNumber = async (id) => {
    try {
      await upOrderNumber(id);
      refetchOrders();
    } catch (error) {
      alert(error?.response?.data?.message || "エラーが発生しました。");
    }
  };

  const handleDownOrderNumber = async (id) => {
    try {
      await downOrderNumber(id);
      refetchOrders();
    } catch (error) {
      alert(error?.response?.data?.message || "エラーが発生しました。");
    }
  };
  const handleUpTopOrderNumber = async (id) => {
    try {
      await upTopOrderNumber(id);
      refetchOrders();
    } catch (error) {
      alert(error?.response?.data?.message || "エラーが発生しました。");
    }
  };
  const handleDownBottomOrderNumber = async (id) => {
    try {
      await downBottomOrderNumber(id);
      refetchOrders();
    } catch (error) {
      alert(error?.response?.data?.message || "エラーが発生しました。");
    }
  };
  const handleStoreChange = async (e, id) => {
    try {
      // confirm change store
      if (!window.confirm("店舗を変更しますか？")) {
        return;
      }
      const response = await changeStoreCalendar(id, e.target.value);
      refetchOrders();
      alert(response?.message);
    } catch (error) {
      alert(error?.response?.data?.message || "エラーが発生しました。");
    }
  };

  const handleDeleteCalendar = async (id) => {
    try {
      // confirm: When you delete this calendar, all orders in this calendar will be deleted. Are you sure?
      if (
        !window.confirm(
          "このカレンダーを削除すると、このカレンダー内のすべての予約が削除されます。本当に削除しますか？"
        )
      ) {
        return;
      }
      const response = await deleteTherapistCalendar(id);
      refetchOrders();
      alert(response?.message);
    } catch (error) {
      alert(error?.response?.data?.message || "エラーが発生しました。");
    }
  };

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      <Container
        maxWidth="md"
        sx={{
          marginTop: {
            xs: 2,
            md: 5,
          },
        }}
      >
        <TitleComponent valueText={"予約管理"} />
      </Container>
      <Container
        maxWidth="xl"
        sx={{
          paddingLeft: {
            sm: 3,
          },
          paddingRight: {
            sm: 3,
          },
        }}
      >
        <Box
          sx={{
            marginTop: "40px",
            paddingRight: "16px",
            paddingLeft: "16px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <Button
                variant="outlined"
                onClick={() =>
                  setCurrentDate((prev) => prev.subtract(1, "day"))
                }
              >
                前日
              </Button>
            </Grid>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <FormControl>
                <DatePickerComponent
                  currentDate={currentDate}
                  onDateChange={setCurrentDate}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <Button
                variant="outlined"
                onClick={() => setCurrentDate((prev) => prev.add(1, "day"))}
              >
                翌日
              </Button>
            </Grid>
          </Grid>
        </Box>
        <MonthlyReverseCountComponent
          days={daysInMonth}
          currentDate={currentDate}
          updateCurrentDate={updateCurrentDate}
          activeDayIndex={activeDayIndex}
          setActiveDayIndex={setActiveDayIndex}
          type={"reservation"}
        />
      </Container>

      <Container
        maxWidth="xl"
        sx={{
          paddingLeft: {
            sm: 3,
          },
          paddingRight: {
            sm: 3,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            marginTop: "20px",
            justifyContent: "flex-end",
          }}
        >
          {countNewOrder > 0 && (
            <Button
              variant="contained"
              color="error"
              style={{ cursor: "default", userSelect: "none" }}
            >
              未確認の新しい予約が{countNewOrder}件あります
            </Button>
          )}
          <Button
            variant="outlined"
            color="error"
            onClick={() => refetchOrders(true)}
            startIcon={<CachedIcon />}
          >
            最新の情報にする
          </Button>
        </Box>
      </Container>

      <Container
        maxWidth="xl"
        sx={{
          paddingLeft: {
            sm: 3,
          },
          paddingRight: {
            sm: 3,
          },
        }}
      >
        <Box sx={{ marginTop: "20px" }}>
          <Paper
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              color: "rgba(0, 0, 0, 0.87)",
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              borderRadius: "4px",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
              width: "100%",
              marginBottom: "16px",
              marginTop: "20px",
              overflow: "auto",
            }}
            className="reservationTable"
          >
            <TableContainer component={Paper}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ display: "flex" }}>
                      <TableCell sx={{ ...styleHead, width: "80px" }}>
                        #
                      </TableCell>
                      <TableCell sx={styleHead}>店舗</TableCell>
                      <TableCell sx={styleHead}>名前</TableCell>
                      {hours.map((hour) => (
                        <TableCell
                          key={hour}
                          align="center"
                          sx={{
                            border: "2px solid #e0e0e0",
                            width: `${pixel1Hour}px`,
                            padding: "auto",
                          }}
                        >
                          {hour}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {calendarList.map((calendar, calendarIndex) => (
                      <TableRow
                        key={calendar.id}
                        sx={{ display: "flex", padding: 0 }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            ...styleSticky,
                            padding: "12px",
                            display: "grid",
                            gridTemplateColumns: "1fr",
                            gridTemplateRows: "1fr",
                            gap: "4px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "4px",
                            }}
                          >
                            <KeyboardArrowUpIcon
                              sx={styleIcon}
                              onClick={() =>
                                handleUpOrderNumber(calendar.calendar_id)
                              }
                            />
                            <KeyboardDoubleArrowUpIcon
                              sx={styleIcon}
                              onClick={() =>
                                handleUpTopOrderNumber(calendar.calendar_id)
                              }
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "4px",
                            }}
                          >
                            <KeyboardArrowDownIcon
                              sx={styleIcon}
                              onClick={() =>
                                handleDownOrderNumber(calendar.calendar_id)
                              }
                            />
                            <KeyboardDoubleArrowDownIcon
                              sx={styleIcon}
                              onClick={() =>
                                handleDownBottomOrderNumber(
                                  calendar.calendar_id
                                )
                              }
                            />
                          </Box>
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <DeleteIcon
                              sx={{
                                color: "#d32f2f",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleDeleteCalendar(calendar.calendar_id)
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            ...styleSticky,
                            left: "80px",
                            border: "2px solid #e0e0e0",
                            width: "80px",
                            background: "#fff",
                          }}
                        >
                          <Select
                            style={{ width: "100%", fontSize: "12px" }}
                            variant="standard"
                            value={calendar.store_id}
                            onChange={(e) =>
                              handleStoreChange(e, calendar.calendar_id)
                            }
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            }}
                          >
                            {dataStore?.data?.data?.map((store) => (
                              <MenuItem key={store.id} value={store.id}>
                                {store.store_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell
                          sx={{
                            ...styleSticky,
                            left: "160px",
                            border: "2px solid #e0e0e0",
                            width: "80px",
                            background: "#fff",
                            fontSize: "12px",
                          }}
                        >
                          {calendar?.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            width:
                              pixel1Hour *
                                (END_HOUR_TIME + 1 - (START_HOUR_TIME - 1)) +
                              "px",
                            padding: 0,
                            backgroundColor: "#808080",
                            position: "relative",
                            zIndex: 2,
                          }}
                        >
                          {renderTimeNow}
                          {renderShift(calendar)}
                          {calendar.rest_times.map((restTime, restIndex) => {
                            return renderRestTime(
                              calendar,
                              restTime,
                              restIndex
                            );
                          })}
                          {calendar.orders.map((order, orderIndex) => {
                            return renderOrder(
                              calendar,
                              order,
                              calendarIndex + orderIndex
                            );
                          })}
                          {calendar.orders_in_other_stores.map(
                            (order, orderIndex) => {
                              return renderOrderInOtherStore(
                                calendar,
                                order,
                                calendarIndex + orderIndex
                              );
                            }
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                    {calendarList.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={hours.length + 3} align="center">
                          <Typography>該当データがありません。</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableContainer>

            <TablePaginationLayout
              rowsPerPage={perPage}
              total={total}
              page={page}
              rowsPerPageOptions={[50, 100, 125]}
              onPageChange={changePage}
              handleChangeRowsPerPage={changePerPage}
            />
          </Paper>
        </Box>
      </Container>
      <ModalReservationRegister
        open={isOpenModal}
        handleClose={() => {
          setIsOpenModal(false);
          setOrderSelected({});
        }}
        item={orderSelected}
        courseOptions={dataCourse?.data?.data || []}
        extensionOptions={dataExtension?.data?.data || []}
        paymentMethodOptions={
          dataPaymentMethod?.data?.data?.sort((a, b) => a.id - b.id) || []
        }
        optionOptions={dataOption?.data?.data || []}
        refetch={() => {
          refetchOrders();
        }}
      />
      <ModalRestTime
        open={isOpenRestTimeModal}
        handleClose={() => {
          setIsOpenRestTimeModal(false);
          setRestTimeSelected({});
        }}
        item={restTimeSelected}
        refetch={() => {
          refetchOrders();
        }}
      />
    </>
  );
}

export default ReservationManagement;
