import {
  Box,
  Container,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Button,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import TablePaginationLayout from "../../layouts/TablePagination";
import useTherapistManagementMaster from "../../features/therapistManagement";

const columnTitles = [
  "ID",
  "名前",
  "掲載",
  "勤務初日",
  // "指名料",
  "トータル本指名数",
  "メモ",
];

const TherapistManagement = () => {
  const {
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    rowsPerPage,
    page,
    total,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleUpdateTherapistManagement,
    handlePublishChange,
  } = useTherapistManagementMaster();

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      <Container maxWidth="lg" sx={{}}>
        <Typography
          component="p"
          sx={{
            fontSize: {
              xs: 20,
              md: 28,
            },
            color: "#fff",
            top: "104px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            margin: "80px 0px 16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid white",
            textAlign: "left",
          }}
        >
          セラピスト管理
        </Typography>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "64px" }}
        >
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "transparent",
              color: "rgba(46, 125, 50)",
              "&:hover": {
                backgroundColor: "#BDBDBD",
              },
              padding: "8px 16px",
              borderRadius: "4px",
              boxShadow: "none",
              border: "1px solid rgba(46, 125, 50, 0.5)",
            }}
            onClick={handleUpdateTherapistManagement}
          >
            表示順位・掲載を保存
          </Button>
        </Box>
      </Container>
      <Container maxWidth="xl">
        <Box sx={{ marginTop: "40px" }}>
          <Paper sx={{ marginTop: "16px" }}>
            <TableContainer>
              <Table aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    {columnTitles.map((title, index) => {
                      const isSorted = activeSortColumn === index;
                      const sortDirection = isSorted
                        ? sortColumnDirection
                        : "asc";

                      return (
                        <TableCell
                          key={index}
                          align="center"
                          sx={{ padding: "8px 16px" }}
                          sortDirection={isSorted ? sortDirection : false}
                        >
                          <TableSortLabel
                            active={isSorted}
                            direction={sortDirection}
                            onClick={() => handleSort(index)}
                          >
                            {title}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row, rowIndex) => (
                    <TableRow key={rowIndex} className="MuiTableRow-hover">
                      <TableCell align="center">{row.id}</TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">
                        <Select
                          variant="standard"
                          value={row.status}
                          onChange={(e) => handlePublishChange(e, rowIndex)}
                        >
                          <MenuItem value="表示">表示</MenuItem>
                          <MenuItem value="非掲載">非掲載</MenuItem>
                        </Select>
                      </TableCell>

                      <TableCell align="center">{row.date}</TableCell>

                      {/* <TableCell align="center">
                        {`指名料 : ${row.nomination_fee}`}
                        <br />
                        {`本指名料 : ${row.main_nomination_fee}`}
                      </TableCell> */}
                      <TableCell align="center">
                        {row.totalNominationCount}
                      </TableCell>
                      <TableCell align="center">{row.remarks}</TableCell>
                    </TableRow>
                  ))}
                  {sortedData.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={columnTitles.length} align="center">
                        <Typography>該当データがありません。</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={rowsPerPage}
              total={total}
              page={page}
              rowsPerPageOptions={[50, 100, 125]}
              onPageChange={handlePageChange}
              handleChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default TherapistManagement;
