import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  createTherapist,
  deleteTherapist,
  getListTherapist,
  updateTherapist,
} from "../../api/therapist";
import { getListIntroducer } from "../../api/introducer";
import { isNumeric } from "../../utils";
import dayjs from "dayjs";

const PASSWORD_TEXT_HIDDEN = "********";

const useTherapistMaster = () => {
  const [arrTherapists, setArrTherapists] = useState([]);
  const [activeSortColumn, setActiveSortColumn] = useState(0);
  const [sortColumnDirection, setSortColumnDirection] = useState("desc");
  const [sortedData, setSortedData] = useState([]);
  const [newTherapist, setNewTherapist] = useState({
    name: "",
    base_password: "",
    training_date: "",
    introducer_id: "",
    remarks: "",
  });
  const [newAvatarFile, setNewAvatarFile] = useState(null);
  const [newAvatarSrc, setNewAvatarSrc] = useState(null);
  const [newAvatarError, setNewAvatarError] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const [editTherapist, setEditTherapist] = useState({
    name: "",
    base_password: "",
    training_date: null,
    introducer_id: "",
    remarks: "",
  });
  const [editAvatarFile, setEditAvatarFile] = useState(null);
  const [editAvatarSrc, setEditAvatarSrc] = useState(null);
  const [editAvatarError, setEditAvatarError] = useState("");

  const [isPopupOpenDelete, setIsPopupOpenDelete] = useState(false);
  const [isPopupOpenUpdate, setIsPopupOpenUpdate] = useState(false);
  const [isPopupManageNominationOpen, setIsPopupManageNominationOpen] = useState(false);
  const [therapistToDelete, setTherapistToDelete] = useState(null);
  const [focusState, setFocusState] = useState({
    name: false,
    base_password: false,
    remarks: false,
  });
  const [arrIntroducers, setArrIntroducers] = useState([]);

  const nameInputRef = useRef(null);

  const [errors, setErrors] = useState({
    name: "",
    base_password: "",
    training_date: "",
    introducer_id: "",
    remarks: "",
  });

  const [errorsUpdate, setErrorsUpdate] = useState({
    name: "",
    base_password: "",
    training_date: "",
    introducer_id: "",
    remarks: "",
  });

  const { data: therapistsData, refetch: refetchTherapists } = useQuery(
    ["therapists", page, rowsPerPage],
    () => {
      const params = {
        page: page + 1,
        per_page: rowsPerPage,
      };
      return getListTherapist(params);
    },
    {
      keepPreviousData: true,
    }
  );

  const { data: introducersData } = useQuery(
    ["introducers", page, rowsPerPage],
    () => {
      const params = {
        page: page + 1,
        per_page: rowsPerPage,
      };
      return getListIntroducer(params);
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (therapistsData) {
      setArrTherapists(transformTherapistData(therapistsData.data.data));
      setTotal(therapistsData.data.total);
    }
  }, [therapistsData]);

  useEffect(() => {
    if (introducersData) {
      setArrIntroducers(transformIntroducerData(introducersData.data.data));
    }
  }, [introducersData]);

  useEffect(() => {
    setSortedData(arrTherapists.sort((a, b) => b.id - a.id));
  }, [arrTherapists]);

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const transformIntroducerData = (data) => {
    return data.map((introducer) => {
      return {
        id: introducer.id.toString(),
        name: introducer.name,
      };
    });
  };

  const transformTherapistData = (data) => {
    return data.map((therapist) => ({
      id: therapist.id.toString(),
      date: new Date(therapist.created_at).toISOString().split("T")[0],
      time: new Date(therapist.created_at).toLocaleTimeString("ja-JP", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }),
      name: therapist.name,
      avatar: therapist.avatar,
      training_date: therapist.training_date,
      base_password: therapist.base_password ? therapist.base_password : PASSWORD_TEXT_HIDDEN,
      introducer_id: therapist?.introducer?.name || "",
      remarks: therapist.remarks || "",
    }));
  };

  const handleSort = (columnIndex) => {
    const isSorted = activeSortColumn === columnIndex;
    let direction = isSorted
      ? sortColumnDirection === "asc"
        ? "desc"
        : "asc"
      : "desc";
    sortedData.sort((a, b) => {
      let aValue = a[Object.keys(a)[columnIndex]];
      let bValue = b[Object.keys(b)[columnIndex]];

      // if aValue and bValue are numberic, sort parseInt
      if (isNumeric(aValue) && isNumeric(bValue)) {
        aValue = parseInt(aValue);
        bValue = parseInt(bValue);
      }

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setSortedData([...sortedData]);
    setActiveSortColumn(columnIndex);
    setSortColumnDirection(direction);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTherapist({ ...newTherapist, [name]: value });
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleAddTherapist = async () => {
    try {
      const newTherapistData = new FormData();
      newTherapistData.append("name", newTherapist.name);
      if (newAvatarFile) {
        newTherapistData.append("avatar", newAvatarFile);
      }
      newTherapistData.append("base_password", newTherapist.base_password);
      newTherapistData.append("training_date", newTherapist.training_date ? newTherapist.training_date.format("YYYY-MM-DD") : "");
      newTherapistData.append("introducer_id", newTherapist.introducer_id);
      newTherapistData.append("remarks", newTherapist.remarks);
      const response = await createTherapist(newTherapistData);

      if (response && response.data) {
        refetchTherapists();
        setNewTherapist({ name: "", base_password: "", introducer_id: "", remarks: "" });
        setNewAvatarFile(null);
        setNewAvatarSrc(null);
        setNewAvatarError("");
        setErrors({ name: "", base_password: "", introducer_id: "", remarks: "" });
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        // check if avatar error exists
        if (fieldErrors.avatar) {
          setNewAvatarError(fieldErrors.avatar);
        } else {
          setErrors((prev) => ({
            ...prev,
            ...fieldErrors,
          }));
        }
      }
      else {
        alert("エラーが発生しました。");
      }
    }
  };

  const handleUpdateTherapist = async (updatedTherapist) => {
    try {
      const updatedData = new FormData();
      updatedData.append("name", updatedTherapist.name);
      if (editAvatarSrc === null) {
        updatedData.append("is_avatar_delete", true);
      }
      if (editAvatarFile) {
        updatedData.append("avatar", editAvatarFile);
        updatedData.append("is_avatar_delete", false);
      }
      updatedData.append("base_password", updatedTherapist.base_password);
      updatedData.append("training_date", updatedTherapist.training_date ? updatedTherapist.training_date.format("YYYY-MM-DD") : "");
      updatedData.append("introducer_id", updatedTherapist.introducer_id);
      updatedData.append("remarks", updatedTherapist.remarks);
      const response = await updateTherapist(updatedTherapist.id, updatedData);

      if (response && response.data) {
        setEditAvatarFile(null);
        setEditAvatarSrc(null);
        setEditAvatarError("");
        refetchTherapists();
        return true
      } else {
        alert("エラーが発生しました。");
        return false
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        // check if avatar error exists
        if (fieldErrors.avatar) {
          setNewAvatarError(fieldErrors.avatar);
        } else {
          setErrorsUpdate((prev) => ({
            ...prev,
            ...fieldErrors,
          }));
        }
      }
      else {
        alert("エラーが発生しました。");
      }
      return false
    }
  };

  const handleDeleteTherapist = async (id) => {
    try {
      const response = await deleteTherapist(id);

      if (response.success) {
        refetchTherapists();
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    const maxPage = Math.floor(total / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(Math.min(page, maxPage));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleFocus = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: true,
    }));
  };

  const handleBlur = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: false,
    }));
  };

  const handleClosePopup = () => {
    setIsPopupOpenUpdate(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditTherapist((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrorsUpdate((prev) => ({ ...prev, [name]: "" }));
  };

  const handleEditClick = (therapist) => {
    const getIntroducerId = (feeValue) => {
      const option = arrIntroducers.find((o) => o.name === feeValue);
      return option ? option.id : "";
    };
    const introducerId = getIntroducerId(therapist.introducer_id);
    const updatedTherapist = {
      ...therapist,
      introducer_id: introducerId,
      training_date: therapist.training_date ? dayjs(therapist.training_date) : null,
      base_password: therapist.base_password === PASSWORD_TEXT_HIDDEN ? '' : therapist.base_password,
    };
    setEditTherapist(updatedTherapist);
    setEditAvatarSrc(therapist.avatar);
    setIsPopupOpenUpdate(true);
  };

  const handleDeleteClick = (therapist) => {
    setTherapistToDelete(therapist);
    setIsPopupOpenDelete(true);
  };

  return {
    arrTherapists,
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    newTherapist,
    rowsPerPage,
    page,
    total,
    editTherapist,
    isPopupOpenDelete,
    isPopupOpenUpdate,
    isPopupManageNominationOpen,
    therapistToDelete,
    focusState,
    nameInputRef,
    arrIntroducers,
    errorsUpdate,
    errors,
    newAvatarSrc,
    newAvatarError,
    editAvatarSrc,
    editAvatarError,
    setNewAvatarFile,
    setNewAvatarSrc,
    setNewAvatarError,
    setEditAvatarFile,
    setEditAvatarSrc,
    setEditAvatarError,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleInputChange,
    handleAddTherapist,
    handleUpdateTherapist,
    handleDeleteTherapist,
    handleFocus,
    handleBlur,
    handleClosePopup,
    handleChange,
    handleEditClick,
    handleDeleteClick,
    setIsPopupOpenDelete,
    setIsPopupOpenUpdate,
    setIsPopupManageNominationOpen,
  };
};

export default useTherapistMaster;
