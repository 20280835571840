import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Button,
  styled,
  FormControl,
  FormLabel,
  InputBase,
  FormHelperText,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import TablePaginationLayout from "../../layouts/TablePagination";
import useCustomerMaster from "../../features/customers";
import { useCallback } from "react";
import PopupComponent from "../../layouts/Popup";
import DeleteIcon from "@mui/icons-material/Delete";

const columnTitles = ["ID", "日付", "時間", "名前", "電話番号", "メモ", "操作"];

const CustomButtonEdit = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
  },
}));

const CustomButtonDelete = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.error.main,
  color: theme.palette.error.main,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: theme.palette.error.light,
    borderColor: theme.palette.error.main,
  },
}));

const CustomSaveButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  backgroundColor: "transparent",
  width: "100%",
  "&:hover": {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
  },
}));

const CustomerMaster = () => {
  const {
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    newCustomer,
    rowsPerPage,
    total,
    page,
    editCustomer,
    customerToDelete,
    isPopupOpenUpdate,
    isPopupOpenDelete,
    focusState,
    nameInputRef,
    errors,
    errorsUpdate,
    setIsPopupOpenDelete,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleInputChange,
    handleAddCustomer,
    handleUpdateCustomer,
    handleDeleteCustomer,
    handleFocus,
    handleBlur,
    handleEditClick,
    handleDeleteClick,
    handleClosePopup,
    handleChange,
  } = useCustomerMaster();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const handleSuccess = await handleUpdateCustomer(editCustomer);
      if (handleSuccess) {
        handleClosePopup();
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleSubmitDelete = useCallback(async () => {
    await handleDeleteCustomer(customerToDelete.id);
    setIsPopupOpenDelete(false);
  }, [customerToDelete, handleDeleteCustomer, setIsPopupOpenDelete]);

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      {isPopupOpenUpdate && (
        <PopupComponent open={isPopupOpenUpdate} onClose={handleClosePopup}>
          <FormControl
            className="MuiFormControl-marginNormal"
            sx={{ margin: "16px 0 8px", width: "100%" }}
            error={!!errorsUpdate.name}
          >
            <FormLabel
              className="MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-colorPrimary"
              htmlFor="name"
              id="name-label"
              sx={{
                position: "absolute",
                display: "block",
                transformOrigin: "left top",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                top: 0,
                left: 0,
                color: "rgba(0, 0, 0, 0.6)",
                transform:
                  focusState.name || editCustomer.name
                    ? "translate(0px, -1.5px) scale(0.75)"
                    : "translate(0px, 20px) scale(1)",
                transition:
                  "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
              }}
            >
              お客様名 *
            </FormLabel>
            <InputBase
              className="MuiInput-root MuiInput-underline"
              id="name"
              name="name"
              type="text"
              onFocus={() => handleFocus("name")}
              onBlur={() => handleBlur("name")}
              inputProps={{ className: "MuiInputBase-input css-mnn31" }}
              aria-invalid="false"
              value={editCustomer.name}
              onChange={handleChange}
              sx={{
                marginTop: "16px",
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                  {
                    borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                    left: 0,
                    bottom: 0,
                    content: '""',
                    position: "absolute",
                    right: 0,
                    transition:
                      "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    pointerEvents: "none",
                  },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                  {
                    borderBottom: "2px solid rgb(0, 0, 0)",
                  },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
                  {
                    borderBottom: "2px solid rgb(25, 118, 210)",
                    left: 0,
                    bottom: 0,
                    content: '""',
                    position: "absolute",
                    right: 0,
                    transform: "translateX(0px) scaleX(1)",
                    transition:
                      "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                    pointerEvents: "none",
                  },
              }}
              inputRef={nameInputRef}
            />
            <FormHelperText>{errorsUpdate.name}</FormHelperText>
          </FormControl>

          <FormControl
            className="MuiFormControl-marginNormal"
            sx={{ margin: "16px 0 8px", width: "100%" }}
            error={!!errorsUpdate.tel}
          >
            <FormLabel
              className="MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-colorPrimary"
              htmlFor="tel"
              id="tel-label"
              sx={{
                position: "absolute",
                display: "block",
                transformOrigin: "left top",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                top: 0,
                left: 0,
                color: "rgba(0, 0, 0, 0.6)",
                transform:
                  focusState.tel || editCustomer.tel
                    ? "translate(0px, -1.5px) scale(0.75)"
                    : "translate(0px, 20px) scale(1)",
                transition:
                  "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
              }}
            >
              TEL *
            </FormLabel>
            <InputBase
              className="MuiInput-root MuiInput-underline"
              id="tel"
              name="tel"
              type="text"
              onFocus={() => handleFocus("tel")}
              onBlur={() => handleBlur("tel")}
              inputProps={{ className: "MuiInputBase-input css-mnn31" }}
              aria-invalid="false"
              value={editCustomer.tel}
              onChange={handleChange}
              sx={{
                marginTop: "16px",
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                  {
                    borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                    left: 0,
                    bottom: 0,
                    content: '""',
                    position: "absolute",
                    right: 0,
                    transition:
                      "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    pointerEvents: "none",
                  },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                  {
                    borderBottom: "2px solid rgb(0, 0, 0)",
                  },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
                  {
                    borderBottom: "2px solid rgb(25, 118, 210)",
                    left: 0,
                    bottom: 0,
                    content: '""',
                    position: "absolute",
                    right: 0,
                    transform: "translateX(0px) scaleX(1)",
                    transition:
                      "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                    pointerEvents: "none",
                  },
              }}
            />
            <FormHelperText>{errorsUpdate.tel}</FormHelperText>
          </FormControl>
          <FormControl sx={{ width: "100%" }} error={!!errorsUpdate.remarks}>
            <textarea
              aria-label="minimum height"
              id="edit_remarks"
              name="remarks"
              placeholder="メモ"
              style={{
                width: "100%",
                height: "60px",
                marginTop: 30,
                borderRadius: 5,
                fontSize: 18,
                backgroundColor: "transparent",
                outline: "none",
                border: errorsUpdate.remarks ? "1px solid red" : "",
              }}
              value={editCustomer.remarks}
              onChange={handleChange}
            />
            <FormHelperText>{errorsUpdate.remarks}</FormHelperText>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="success"
            style={{ marginTop: 20 }}
            onClick={handleSubmit}
          >
            保存
          </Button>
        </PopupComponent>
      )}
      {isPopupOpenDelete && (
        <PopupComponent
          open={isPopupOpenDelete}
          onClose={() => setIsPopupOpenDelete(false)}
        >
          <Typography variant="h4" gutterBottom>
            確認
          </Typography>
          <Typography variant="body1" paragraph>
            削除するデータを選択しています。続行しますか?
          </Typography>

          <Button
            type="button"
            fullWidth
            variant="outlined"
            startIcon={<DeleteIcon />}
            color="error"
            style={{ marginTop: "20px" }}
            onClick={handleSubmitDelete}
          >
            消す
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setIsPopupOpenDelete(false)}
            fullWidth
            style={{ marginTop: "20px" }}
          >
            キャンセル
          </Button>
        </PopupComponent>
      )}
      <Container maxWidth="lg" sx={{}}>
        <Typography
          component="p"
          sx={{
            fontSize: {
              xs: 20,
              md: 28,
            },
            color: "#fff",
            top: "104px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            margin: "80px 0px 16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid white",
            textAlign: "left",
          }}
        >
          顧客マスター
        </Typography>
        <Box sx={{ marginTop: "40px", maxWidth: "100%" }}>
          <Typography
            component="p"
            sx={{
              fontSize: {
                xs: 20,
                md: 28,
              },
              color: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "24px",
              letterSpacing: "3px",
              margin: "80px 0px 16px",
              textDecoration: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            【新規登録】
          </Typography>
          <Paper
            sx={{
              padding: "16px",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <Grid container spacing={2} direction="column">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="お客様名"
                  name="name"
                  value={newCustomer.name}
                  onChange={handleInputChange}
                  required
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="TEL"
                  name="tel"
                  value={newCustomer.tel}
                  onChange={handleInputChange}
                  required
                  error={!!errors.tel}
                  helperText={errors.tel ? errors.tel : "※ハイフン無し"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="メモ"
                  name="remarks"
                  value={newCustomer.remarks}
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                  error={!!errors.remarks}
                  helperText={errors.remarks}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <CustomSaveButton
                  variant="outlined"
                  onClick={handleAddCustomer}
                >
                  保存
                </CustomSaveButton>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Box sx={{ marginTop: "40px", maxWidth: "100%" }}>
          <Typography
            component="p"
            sx={{
              fontSize: {
                xs: 20,
                md: 28,
              },
              color: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "24px",
              letterSpacing: "3px",
              margin: "80px 0px 16px",
              textDecoration: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            【登録済み一覧】
          </Typography>
          <Paper
            sx={{
              marginTop: "16px",
              backgroundColor: "#fff",
              boxShadow: "none",
            }}
          >
            <TableContainer>
              <Table aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    {columnTitles.map((title, index) => {
                      const isSorted = activeSortColumn === index;
                      const sortDirection = isSorted
                        ? sortColumnDirection
                        : "asc";

                      return (
                        <TableCell
                          key={index}
                          align="center"
                          sx={{ padding: "8px 16px", color: "#000" }}
                          sortDirection={isSorted ? sortDirection : false}
                        >
                          <TableSortLabel
                            active={isSorted}
                            direction={sortDirection}
                            onClick={() => handleSort(index)}
                          >
                            {title}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(sortedData) &&
                    sortedData.map((row, rowIndex) => (
                      <TableRow key={rowIndex} className="MuiTableRow-hover">
                        {Object.entries(row).map(([key, value], cellIndex) => (
                          <TableCell
                            key={cellIndex}
                            align="center"
                            sx={{ color: "#000" }}
                          >
                            {value}
                          </TableCell>
                        ))}
                        <TableCell align="center">
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <CustomButtonEdit
                              variant="outlined"
                              onClick={() => handleEditClick(row)}
                            >
                              編集
                            </CustomButtonEdit>
                            <CustomButtonDelete
                              variant="outlined"
                              sx={{ marginLeft: 1 }}
                              onClick={() => handleDeleteClick(row)}
                            >
                              削除
                            </CustomButtonDelete>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  {(!sortedData || sortedData.length === 0) && (
                    <TableRow>
                      <TableCell colSpan={columnTitles.length} align="center">
                        <Typography>該当データがありません。</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={rowsPerPage}
              total={total}
              page={page}
              rowsPerPageOptions={[50, 100, 125]}
              onPageChange={handlePageChange}
              handleChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default CustomerMaster;
