import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Button,
  styled,
  FormHelperText,
  InputBase,
  FormLabel,
  FormControl,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import { useCallback } from "react";
import TablePaginationLayout from "../../layouts/TablePagination";
import useCourseMaster from "../../features/courses";
import PopupComponent from "../../layouts/Popup";
import DeleteIcon from "@mui/icons-material/Delete";
import NavButton from "../../layouts/NavButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import PopupCourseOption from "../../components/PopupCourseOption";

const styleIcon = {
  color: "#ED6C02",
  cursor: "pointer",
};

const columnTitles = [
  "id",
  "コース名",
  "お客様へ請求する金額",
  "セラピストへ支払われる金額",
  "コース時間",
  "メモ",
  "操作",
];

const CustomButtonEdit = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
  },
}));

const CustomButtonDelete = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.error.main,
  color: theme.palette.error.main,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: theme.palette.error.light,
    borderColor: theme.palette.error.main,
  },
}));

const CustomSaveButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  backgroundColor: "transparent",
  width: "100%",
  "&:hover": {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
  },
}));

const AccountMasterCourse = () => {
  const {
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    newCourse,
    rowsPerPage,
    total,
    page,
    editCourse,
    courseToDelete,
    isPopupOpenUpdate,
    isPopupOpenModalOption,
    isPopupOpenDelete,
    focusState,
    nameInputRef,
    errors,
    errorsUpdate,
    refetch,
    setIsPopupOpenUpdate,
    setIsPopupOpenModalOption,
    setIsPopupOpenDelete,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleInputChange,
    handleAddCourse,
    handleUpdateCourse,
    handleDeleteCourse,
    handleFocus,
    handleBlur,
    handleEditClick,
    handleDeleteClick,
    handleClosePopup,
    handleChange,
    handleUpOrderNumber,
    handleDownOrderNumber,
    handleUpTopOrderNumber,
    handleDownBottomOrderNumber,
  } = useCourseMaster();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const handleSuccess = await handleUpdateCourse(editCourse);
      if (handleSuccess) {
        handleClosePopup();
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleSubmitDelete = useCallback(async () => {
    await handleDeleteCourse(courseToDelete.id);
    setIsPopupOpenDelete(false);
  }, [courseToDelete, handleDeleteCourse, setIsPopupOpenDelete]);

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      {isPopupOpenUpdate && (
        <PopupComponent open={isPopupOpenUpdate} onClose={handleClosePopup}>
          <FormControl
            className="MuiFormControl-marginNormal"
            sx={{ margin: "16px 0 8px", width: "100%" }}
            error={!!errorsUpdate.name}
          >
            <FormLabel
              className="MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-colorPrimary"
              htmlFor="name"
              id="name-label"
              sx={{
                position: "absolute",
                display: "block",
                transformOrigin: "left top",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                top: 0,
                left: 0,
                color: "rgba(0, 0, 0, 0.6)",
                transform:
                  focusState.name || editCourse.name
                    ? "translate(0px, -1.5px) scale(0.75)"
                    : "translate(0px, 20px) scale(1)",
                transition:
                  "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
              }}
            >
              コース名 *
            </FormLabel>
            <InputBase
              className="MuiInput-root MuiInput-underline"
              id="name"
              name="name"
              type="text"
              onFocus={() => handleFocus("name")}
              onBlur={() => handleBlur("name")}
              inputProps={{ className: "MuiInputBase-input css-mnn31" }}
              aria-invalid="false"
              value={editCourse.name}
              onChange={handleChange}
              sx={{
                marginTop: "16px",
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                  {
                    borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                    left: 0,
                    bottom: 0,
                    content: '""',
                    position: "absolute",
                    right: 0,
                    transition:
                      "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    pointerEvents: "none",
                  },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                  {
                    borderBottom: "2px solid rgb(0, 0, 0)",
                  },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
                  {
                    borderBottom: "2px solid rgb(25, 118, 210)",
                    left: 0,
                    bottom: 0,
                    content: '""',
                    position: "absolute",
                    right: 0,
                    transform: "translateX(0px) scaleX(1)",
                    transition:
                      "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                    pointerEvents: "none",
                  },
              }}
              inputRef={nameInputRef}
            />
            <FormHelperText>{errorsUpdate.name}</FormHelperText>
          </FormControl>

          <FormControl
            className="MuiFormControl-marginNormal"
            sx={{ margin: "16px 0 8px", width: "100%" }}
            error={!!errorsUpdate.cost}
          >
            <FormLabel
              className="MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-colorPrimary"
              htmlFor="cost"
              id="cost-label"
              sx={{
                position: "absolute",
                display: "block",
                transformOrigin: "left top",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                top: 0,
                left: 0,
                color: "rgba(0, 0, 0, 0.6)",
                transform:
                  focusState.cost || editCourse.cost
                    ? "translate(0px, -1.5px) scale(0.75)"
                    : "translate(0px, 20px) scale(1)",
                transition:
                  "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
              }}
            >
              お客様に請求する金額 *
            </FormLabel>
            <InputBase
              className="MuiInput-root MuiInput-underline"
              id="cost"
              name="cost"
              type="number"
              onFocus={() => handleFocus("cost")}
              onBlur={() => handleBlur("cost")}
              inputProps={{ className: "MuiInputBase-input css-mnn31" }}
              aria-invalid="false"
              value={editCourse.cost}
              onChange={handleChange}
              onKeyDown={(e) => {
                if (
                  e.key === "e" ||
                  e.key === "E" ||
                  e.key === "-" ||
                  e.key === "+" ||
                  e.key === "."
                ) {
                  e.preventDefault();
                }
              }}
              sx={{
                marginTop: "16px",
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                  {
                    borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                    left: 0,
                    bottom: 0,
                    content: '""',
                    position: "absolute",
                    right: 0,
                    transition:
                      "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    pointerEvents: "none",
                  },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                  {
                    borderBottom: "2px solid rgb(0, 0, 0)",
                  },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
                  {
                    borderBottom: "2px solid rgb(25, 118, 210)",
                    left: 0,
                    bottom: 0,
                    content: '""',
                    position: "absolute",
                    right: 0,
                    transform: "translateX(0px) scaleX(1)",
                    transition:
                      "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                    pointerEvents: "none",
                  },
              }}
            />
            <FormHelperText>{errorsUpdate.cost}</FormHelperText>
          </FormControl>

          <FormControl
            className="MuiFormControl-marginNormal"
            sx={{ margin: "16px 0 8px", width: "100%" }}
            error={!!errorsUpdate.therapist_amount}
          >
            <FormLabel
              className="MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-colorPrimary"
              htmlFor="therapist_amount"
              id="therapist_amount-label"
              sx={{
                position: "absolute",
                display: "block",
                transformOrigin: "left top",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                top: 0,
                left: 0,
                color: "rgba(0, 0, 0, 0.6)",
                transform:
                  focusState.therapist_amount || editCourse.therapist_amount
                    ? "translate(0px, -1.5px) scale(0.75)"
                    : "translate(0px, 20px) scale(1)",
                transition:
                  "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
              }}
            >
              セラピストに支払われる金額 *
            </FormLabel>
            <InputBase
              className="MuiInput-root MuiInput-underline"
              id="therapist_amount"
              name="therapist_amount"
              type="number"
              onFocus={() => handleFocus("therapist_amount")}
              onBlur={() => handleBlur("therapist_amount")}
              inputProps={{ className: "MuiInputBase-input css-mnn31" }}
              aria-invalid="false"
              value={editCourse.therapist_amount}
              onChange={handleChange}
              onKeyDown={(e) => {
                if (
                  e.key === "e" ||
                  e.key === "E" ||
                  e.key === "-" ||
                  e.key === "+" ||
                  e.key === "."
                ) {
                  e.preventDefault();
                }
              }}
              sx={{
                marginTop: "16px",
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                  {
                    borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                    left: 0,
                    bottom: 0,
                    content: '""',
                    position: "absolute",
                    right: 0,
                    transition:
                      "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    pointerEvents: "none",
                  },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                  {
                    borderBottom: "2px solid rgb(0, 0, 0)",
                  },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
                  {
                    borderBottom: "2px solid rgb(25, 118, 210)",
                    left: 0,
                    bottom: 0,
                    content: '""',
                    position: "absolute",
                    right: 0,
                    transform: "translateX(0px) scaleX(1)",
                    transition:
                      "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                    pointerEvents: "none",
                  },
              }}
            />
            <FormHelperText>{errorsUpdate.therapist_amount}</FormHelperText>
          </FormControl>

          <FormControl
            className="MuiFormControl-marginNormal"
            sx={{ margin: "16px 0 8px", width: "100%" }}
            error={!!errorsUpdate.duration}
          >
            <FormLabel
              className="MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-colorPrimary"
              htmlFor="duration"
              id="duration-label"
              sx={{
                position: "absolute",
                display: "block",
                transformOrigin: "left top",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                top: 0,
                left: 0,
                color: "rgba(0, 0, 0, 0.6)",
                transform:
                  focusState.duration || editCourse.duration
                    ? "translate(0px, -1.5px) scale(0.75)"
                    : "translate(0px, 20px) scale(1)",
                transition:
                  "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
              }}
            >
              コース時間 *
            </FormLabel>
            <InputBase
              className="MuiInput-root MuiInput-underline"
              id="duration"
              name="duration"
              type="text"
              onFocus={() => handleFocus("duration")}
              onBlur={() => handleBlur("duration")}
              inputProps={{ className: "MuiInputBase-input css-mnn31" }}
              aria-invalid="false"
              value={editCourse.duration}
              onChange={handleChange} // Thêm sự kiện onChange
              sx={{
                marginTop: "16px",
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                  {
                    borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                    left: 0,
                    bottom: 0,
                    content: '""',
                    position: "absolute",
                    right: 0,
                    transition:
                      "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    pointerEvents: "none",
                  },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                  {
                    borderBottom: "2px solid rgb(0, 0, 0)",
                  },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
                  {
                    borderBottom: "2px solid rgb(25, 118, 210)",
                    left: 0,
                    bottom: 0,
                    content: '""',
                    position: "absolute",
                    right: 0,
                    transform: "translateX(0px) scaleX(1)",
                    transition:
                      "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                    pointerEvents: "none",
                  },
              }}
            />
            <FormHelperText>{errorsUpdate.duration}</FormHelperText>
          </FormControl>

          <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
            <Button
              type="button"
              variant="outlined"
              color="success"
              onClick={() => {
                setIsPopupOpenUpdate(false);
                setIsPopupOpenModalOption(true);
              }}
            >
              オプションの編集
            </Button>
          </div>

          <FormControl sx={{ width: "100%" }} error={!!errorsUpdate.remarks}>
            <textarea
              aria-label="minimum height"
              id="edit_remarks"
              name="remarks"
              placeholder="メモ"
              style={{
                width: "100%",
                height: "60px",
                marginTop: 30,
                borderRadius: 5,
                fontSize: 18,
                backgroundColor: "transparent",
                outline: "none",
              }}
              value={editCourse.remarks}
              onChange={handleChange}
            />
            <FormHelperText>{errorsUpdate.remarks}</FormHelperText>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="success"
            style={{ marginTop: 20 }}
            onClick={handleSubmit}
          >
            保存
          </Button>
        </PopupComponent>
      )}

      <PopupCourseOption
        isOpen={isPopupOpenModalOption}
        onClose={() => {
          setIsPopupOpenModalOption(false);
        }}
        onSave={() => {
          refetch();
          setIsPopupOpenModalOption(false);
        }}
        course={editCourse}
      />

      {isPopupOpenDelete && (
        <PopupComponent
          open={isPopupOpenDelete}
          onClose={() => setIsPopupOpenDelete(false)}
        >
          <Typography variant="h4" gutterBottom>
            確認
          </Typography>
          <Typography variant="body1" paragraph>
            削除するデータを選択しています。続行しますか?
          </Typography>

          <Button
            type="button"
            fullWidth
            variant="outlined"
            startIcon={<DeleteIcon />}
            color="error"
            style={{ marginTop: "20px" }}
            onClick={handleSubmitDelete}
          >
            消す
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setIsPopupOpenDelete(false)}
            fullWidth
            style={{ marginTop: "20px" }}
          >
            キャンセル
          </Button>
        </PopupComponent>
      )}
      <Container maxWidth="lg">
        <NavButton />
        <Typography
          component="p"
          sx={{
            fontSize: {
              xs: 20,
              md: 28,
            },
            color: "#fff",
            top: "104px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            margin: "80px 0px 16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid white",
            textAlign: "left",
          }}
        >
          コース登録
        </Typography>
        <Box sx={{ marginTop: "40px", maxWidth: "100%" }}>
          <Typography
            component="p"
            sx={{
              fontSize: {
                xs: 20,
                md: 28,
              },
              color: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "24px",
              letterSpacing: "3px",
              margin: "80px 0px 16px",
              textDecoration: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            【新規登録】
          </Typography>
          <Paper
            sx={{
              padding: "16px",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <Grid container spacing={2} direction="column">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="コース名"
                  name="name"
                  value={newCourse.name}
                  onChange={handleInputChange}
                  required
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="お客様へ請求する金額"
                  name="cost"
                  value={newCourse.cost}
                  onChange={handleInputChange}
                  required
                  type="number"
                  onKeyDown={(e) => {
                    if (
                      e.key === "e" ||
                      e.key === "E" ||
                      e.key === "-" ||
                      e.key === "+" ||
                      e.key === "."
                    ) {
                      e.preventDefault();
                    }
                  }}
                  error={!!errors.cost}
                  helperText={errors.cost}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="セラピストへ支払われる金額"
                  name="therapist_amount"
                  type="number"
                  onKeyDown={(e) => {
                    if (
                      e.key === "e" ||
                      e.key === "E" ||
                      e.key === "-" ||
                      e.key === "+" ||
                      e.key === "."
                    ) {
                      e.preventDefault();
                    }
                  }}
                  value={newCourse.therapist_amount}
                  onChange={handleInputChange}
                  required
                  error={!!errors.therapist_amount}
                  helperText={errors.therapist_amount}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="コース時間"
                  name="duration"
                  value={newCourse.duration}
                  onChange={handleInputChange}
                  required
                  error={!!errors.duration}
                  helperText={errors.duration}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="メモ"
                  name="remarks"
                  value={newCourse.remarks}
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                  error={!!errors.remarks}
                  helperText={errors.remarks}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <CustomSaveButton variant="outlined" onClick={handleAddCourse}>
                  保存
                </CustomSaveButton>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Box sx={{ marginTop: "40px", maxWidth: "100%" }}>
          <Typography
            component="p"
            sx={{
              fontSize: {
                xs: 20,
                md: 28,
              },
              color: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "24px",
              letterSpacing: "3px",
              margin: "80px 0px 16px",
              textDecoration: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            【登録済み一覧】
          </Typography>
          <Paper
            sx={{
              marginTop: "16px",
              backgroundColor: "#fff",
              boxShadow: "none",
            }}
          >
            <TableContainer>
              <Table aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    <TableCell
                      key={"order_number"}
                      align="center"
                      sx={{ padding: "8px 16px", color: "#000" }}
                    >
                      　
                    </TableCell>
                    {columnTitles.map((title, index) => {
                      const isSorted = activeSortColumn === index + 1;
                      const sortDirection = isSorted
                        ? sortColumnDirection
                        : "asc";

                      return (
                        <TableCell
                          key={index}
                          align="center"
                          sx={{ padding: "8px 16px", color: "#000" }}
                          sortDirection={isSorted ? sortDirection : false}
                        >
                          <TableSortLabel
                            active={isSorted}
                            direction={sortDirection}
                            onClick={() => handleSort(index + 1)}
                          >
                            {title}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row, rowIndex) => (
                    <TableRow key={rowIndex} className="MuiTableRow-hover">
                      <TableCell align="center" key={"order_number"}>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gridTemplateRows: "1fr 1fr",
                            gap: "4px",
                          }}
                        >
                          <KeyboardArrowUpIcon
                            sx={styleIcon}
                            onClick={() => handleUpOrderNumber(row.id)}
                          />
                          <KeyboardDoubleArrowUpIcon
                            sx={styleIcon}
                            onClick={() => handleUpTopOrderNumber(row.id)}
                          />
                          <KeyboardArrowDownIcon
                            sx={styleIcon}
                            onClick={() => handleDownOrderNumber(row.id)}
                          />
                          <KeyboardDoubleArrowDownIcon
                            sx={styleIcon}
                            onClick={() => handleDownBottomOrderNumber(row.id)}
                          />
                        </Box>
                      </TableCell>
                      {Object.entries(row).map(
                        ([key, value], cellIndex) =>
                          key !== "order_number" &&
                          key !== "options" && (
                            <TableCell
                              key={cellIndex}
                              align="center"
                              sx={{ color: "#000" }}
                            >
                              {value}
                            </TableCell>
                          )
                      )}
                      <TableCell align="center">
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <CustomButtonEdit
                            variant="outlined"
                            onClick={() => handleEditClick(row)}
                          >
                            編集
                          </CustomButtonEdit>
                          <CustomButtonDelete
                            variant="outlined"
                            onClick={() => handleDeleteClick(row)}
                          >
                            削除
                          </CustomButtonDelete>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  {sortedData.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={columnTitles.length} align="center">
                        <Typography>該当データがありません。</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={rowsPerPage}
              total={total}
              page={page}
              rowsPerPageOptions={[50, 100, 125]}
              onPageChange={handlePageChange}
              handleChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default AccountMasterCourse;
