import { Button, Grid, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import CustomeListItem from "../../components/ListItem";
const menuItems = [
  {
    text: "メニュー",
    list: [
      { subText: "予約管理", route: "/reservemgr" },
      { subText: "顧客管理", route: "/customermgr" },
      { subText: "売上管理", route: "/salesmgr" },
      { subText: "基本情報", route: "/basicinformation" },
      { subText: "日報", route: "/dailyreport" },
      { subText: "月報", route: "/monthlyreport" },
      { subText: "スタッフスケジュール", route: "/staff_schedule" },
    ],
    class: "menu",
  },
  {
    text: "セラピスト",
    list: [
      { subText: "セラピスト管理", route: "/therapistmanagement" },
      { subText: "セラピストのシフト設定", route: "/therapistshift" },
    ],
    class: "therapist",
  },
  {
    text: "マスター登録",
    list: [
      { subText: "顧客登録", route: "/customermaster" },
      { subText: "店舗登録", route: "/storemaster" },
      { subText: "セラピスト登録", route: "/therapistmaster" },
      { subText: "メニュー登録", route: "/accountmaster_course" },
      { subText: "紹介者登録", route: "/referrermaster" },
      { subText: "管理者登録", route: "/usermaster" },
    ],
  },
  { text: "ログ", list: [{ subText: "登録・変更履歴のログ", route: "/activity_log" }] },
];
function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedItemIndex(index);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedItemIndex(null);
  };

  const handleMenuItemClick = (route, openNewTab) => {
    if (openNewTab && location.pathname !== route) {
      window.open(route, "_blank");
      return;
    }
    navigate(route);
    handleCloseMenu();
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      className="nav-bar"
    >
      {menuItems.map((item, index) => (
        <Grid key={item.text}>
          <Button
            aria-controls={anchorEl ? `menu-${index}` : undefined}
            style={{ color: "white", lineHeight: 0 }}
            onClick={(event) => handleOpenMenu(event, index)}
          >
            <h3 style={{ paddingTop: "4px", paddingBottom: "4px" }}>
              {item.text}
            </h3>
          </Button>
          <Menu
            id={`menu-${index}`}
            anchorEl={anchorEl}
            open={selectedItemIndex === index}
            onClose={handleCloseMenu}
          >
            {item.list &&
              item.list.map((listItem, listItemIndex) => (
                <MenuItem
                  key={listItemIndex}
                  onClick={() => handleMenuItemClick(listItem.route, listItem.openNewTab)}
                  style={{ color: "rgb(237, 178, 75)" }}
                >
                  {listItem.subText}
                </MenuItem>
              ))}
          </Menu>
        </Grid>
      ))}
    </Grid>
  );
}

export default Navbar;
