import { AddToPhotosOutlined } from "@mui/icons-material";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Modal,
  SvgIcon,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import "./index.css";
import { useCallback, useEffect, useState } from "react";
import PopupFillerMaster from "../PopupFillerMaster";
import PopupGenreMaster from "../PopupGenreMaster";
import { Controller, useForm, useWatch } from "react-hook-form";
import { getListGenres } from "../../api/genres";
import { useQuery } from "react-query";
import { DAILY_TYPE, DAY_OF_WEEK_TEXT_LARGE_ARR, EVERY_DAY_OF_THE_WEEK_TYPE, NONE_REPEAT_TYPE, ONE_DAY_IN_EVERY_WEEK_TYPE, ONLY_UPDATE_THIS_EVENT, STALE_TIME_DEFAULT, UPDATE_ALL_EVENTS, UPDATE_ALL_FUTURE_EVENTS } from "../../constants";
import { getListInputter } from "../../api/inputter";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createStaffSchedule,
  deleteStaffSchedule,
  updateStaffSchedule,
} from "../../api/staffSchedule";
import dayjs from "dayjs";
import { handleSetErrorToField } from "../../utils";

const defaultValue = {
  title: "",
  start_time: undefined,
  inputter_id: "",
  genre_id: "",
  notification_time: 0,
  remarks: "",
  is_all_day: true,
  repeat_type: NONE_REPEAT_TYPE,
  repeat_end_date: undefined,
};

function PopupEventComponent({
  handleClose,
  dateSelected,
  item,
  refetchStaffSchedule,
}) {
  const [fillerModalIsOpen, setFillerModalIsOpen] = useState(false);
  const [genreModalIsOpen, setGenreModalIsOpen] = useState(false);

  const [genresList, setGenresList] = useState([]);
  const [inputterList, setInputterList] = useState([]);

  const [isOpenModalUpdateType, setIsOpenModalUpdateType] = useState(false);
  const [isOpenModalDeleteType, setIsOpenModalDeleteType] = useState(false);
  const [updateType, setUpdateType] = useState(ONLY_UPDATE_THIS_EVENT);
  const [deleteType, setDeleteType] = useState(ONLY_UPDATE_THIS_EVENT);
  const [listUpdateType, setListUpdateType] = useState([ONLY_UPDATE_THIS_EVENT, UPDATE_ALL_FUTURE_EVENTS, UPDATE_ALL_EVENTS]);

  const {
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
  });

  const id = useWatch({ control, name: "id", defaultValue: "" });
  const title = useWatch({ control, name: "title", defaultValue: "" });
  const inputterId = useWatch({
    control,
    name: "inputter_id",
    defaultValue: "",
  });
  const genreId = useWatch({ control, name: "genre_id", defaultValue: "" });
  const startTime = useWatch({
    control,
    name: "start_time",
    defaultValue: null,
  });
  const notificationTime = useWatch({
    control,
    name: "notification_time",
    defaultValue: 0,
  });
  const remarks = useWatch({ control, name: "remarks", defaultValue: "" });
  const isAllDay = useWatch({
    control,
    name: "is_all_day",
    defaultValue: false,
  });
  const repeatType = useWatch({
    control,
    name: "repeat_type",
    defaultValue: defaultValue.repeat_type,
  });
  const repeatEndDate = useWatch({
    control,
    name: "repeat_end_date",
    defaultValue: defaultValue.repeat_end_date,
  });


  const { data: dataGenres, refetch: refetchGenres } = useQuery(
    ["list_all_genres"],
    () => {
      return getListGenres({
        per_page: 300,
      });
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME_DEFAULT,
    }
  );

  const { data: dataInputter, refetch: refetchInputter } = useQuery(
    ["list_all_inputter"],
    () => {
      return getListInputter({
        per_page: 300,
      });
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME_DEFAULT,
    }
  );

  const refetchAllData = () => {
    refetchInputter();
    refetchGenres();
    refetchStaffSchedule();
  };

  useEffect(() => {
    try {
      if (dataInputter) {
        setInputterList(dataInputter?.data?.data);
      }
    } catch (error) { }
  }, [dataInputter]);

  useEffect(() => {
    try {
      if (dataGenres) {
        setGenresList(dataGenres?.data?.data);
      }
    } catch (error) { }
  }, [dataGenres]);

  useEffect(() => {
    if (item) {
      const detail = item.detail;
      reset({
        id: detail?.id || "",
        title: detail?.title || "",
        start_time: detail?.start_time
          ? dayjs(detail.start_time)
          : dayjs(dateSelected),
        inputter_id: detail?.inputter?.id || "",
        genre_id: detail?.genre?.id || "",
        notification_time: detail?.notification_time || "",
        remarks: detail?.remarks || "",
        is_all_day: !!detail?.is_all_day,
        repeat_type: detail?.repeat_type || NONE_REPEAT_TYPE,
        repeat_end_date: detail?.repeat_end_date
          ? dayjs(detail.repeat_end_date)
          : dayjs(dateSelected).add(1, "day"),
        updateType: ONLY_UPDATE_THIS_EVENT,
      });
    } else {
      reset({
        ...defaultValue,
        start_time: dayjs(dateSelected),
        repeat_end_date: dayjs(dateSelected).add(1, "day"),
      });
    }
  }, [item, dateSelected, reset]);

  const handleCreateRecord = async () => {
    try {
      const params = {
        title,
        start_time: isAllDay ? dayjs(startTime).format("YYYY-MM-DD 00:00:00") : dayjs(startTime).format("YYYY-MM-DD HH:mm:ss"),
        inputter_id: inputterId,
        genre_id: genreId,
        notification_time: notificationTime,
        remarks,
        is_all_day: isAllDay ? 1 : 0,
        repeat_type: repeatType ? repeatType : null,
        repeat_end_date: repeatEndDate.format("YYYY-MM-DD"),
      };
      await createStaffSchedule(params);
      reset(defaultValue);
      refetchStaffSchedule();
      handleClose();
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          if (error.response.data.message) {
            const messages = error.response.data.message;
            if (typeof messages === "object") {
              handleSetErrorToField(messages, setError);
              return;
            }
          }
        } else if (error.response.status === 406) {
          if (error.response.data.message) {
            const messages = error.response.data.message;
            alert(messages || "エラーが発生しました。");
          }
        }
      }
    }
  };

  const handleUpdateRecord = useCallback(async (confirmedUpdateType) => {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (id && confirm("スケジュールを変更しますか？")) {
        const params = {
          title,
          start_time: isAllDay ? dayjs(startTime).format("YYYY-MM-DD 00:00:00") : dayjs(startTime).format("YYYY-MM-DD HH:mm:ss"),
          inputter_id: inputterId,
          genre_id: genreId,
          notification_time: notificationTime,
          remarks,
          is_all_day: isAllDay ? 1 : 0,
          repeat_type: repeatType ? repeatType : null,
          repeat_end_date: repeatEndDate.format("YYYY-MM-DD"),
          update_type: confirmedUpdateType || updateType,
        };
        const res = await updateStaffSchedule(id, params);

        alert(res?.message || "Update success!");
        reset(defaultValue);
        refetchStaffSchedule();
        handleClose();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          if (error.response.data.message) {
            const messages = error.response.data.message;
            if (typeof messages === "object") {
              handleSetErrorToField(messages, setError);
              return;
            }
          }
        } else if (error.response.status === 406) {
          if (error.response.data.message) {
            const messages = error.response.data.message;
            alert(messages || "エラーが発生しました。");
          }
        }
      }
    }
  }, [id, title, startTime, inputterId, genreId, notificationTime, remarks, isAllDay, repeatType, repeatEndDate, updateType, reset, refetchStaffSchedule, handleClose, setError]);

  const handleDeleteRecord = useCallback(async () => {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (id && confirm("スケジュールを削除しますか？")) {
        const params = {
          delete_type: deleteType,
        };
        const res = await deleteStaffSchedule(id, params);
        alert(res?.message || "Delete success!");
        reset(defaultValue);
        refetchStaffSchedule();
        handleClose();
      }
    } catch (error) {
      alert("Delete error!");
    }
  }, [id, reset, refetchStaffSchedule, handleClose, deleteType]);

  const confirmUpdateType = useCallback(() => {
    if (!id || !item.detail) return;

    const params = {
      title,
      start_time: isAllDay ? dayjs(startTime).format("YYYY-MM-DD 00:00:00") : dayjs(startTime).format("YYYY-MM-DD HH:mm:ss"),
      inputter_id: inputterId || undefined,
      genre_id: genreId || undefined,
      notification_time: notificationTime,
      remarks,
      is_all_day: isAllDay ? 1 : 0,
      repeat_type: repeatType ? repeatType : null,
      repeat_end_date: repeatEndDate,
    };
    let confirmedUpdateType = updateType;

    var isShowModal = true;
    if (!item.detail.repeat_type && !repeatType) {
      // no repeat, ignore update_type
      isShowModal = false;
    } else if (!item.detail.repeat_type && repeatType) {
      // no repeat -> repeat, ignore update_type
      isShowModal = false;
    } else if (item.detail.repeat_type && !repeatType) {
      // repeat -> no repeat, ignore update_type
      isShowModal = false;
    } else if (
      item.detail.title === title &&
      item.detail.start_time === params.start_time &&
      item.detail.inputter?.id === params.inputter_id &&
      item.detail.genre?.id === params.genre_id &&
      (item.detail.notification_time ?? "") === params.notification_time &&
      (item.detail.remarks ?? "") === params.remarks &&
      item.detail.is_all_day === params.is_all_day &&
      item.detail.repeat_end_date === params.repeat_end_date.format("YYYY-MM-DD")
    ) {
      // no change info, ignore update_type
      isShowModal = false;
      confirmedUpdateType = UPDATE_ALL_FUTURE_EVENTS;
    } else if (item.detail.repeat_end_date !== params.repeat_end_date.format("YYYY-MM-DD")) {
      // change repeat end date, ignore update_type
      isShowModal = false;
      confirmedUpdateType = UPDATE_ALL_EVENTS;
    }

    if (item.detail.repeat_type && repeatType && item.detail.repeat_type !== repeatType) {
      isShowModal = true;
      setUpdateType(UPDATE_ALL_FUTURE_EVENTS);
      setListUpdateType([UPDATE_ALL_FUTURE_EVENTS, UPDATE_ALL_EVENTS]);
    }

    if (isShowModal) {
      setIsOpenModalUpdateType(true);
    } else {
      handleUpdateRecord(confirmedUpdateType);
    }
  }, [id, title, startTime, inputterId, genreId, notificationTime, remarks, isAllDay, repeatType, repeatEndDate, updateType, item, handleUpdateRecord]);

  const confirmDeleteType = useCallback(() => {
    if (!id || !item.detail) return;

    if (item.detail.repeat_type) {
      setIsOpenModalDeleteType(true);
    } else {
      handleDeleteRecord();
    }
  }, [id, item, handleDeleteRecord]);

  const handleDuplidateEvent = useCallback(() => {
    if (item) {
      const detail = item.detail;
      reset({
        id: "",
        title: detail?.title || "",
        start_time: detail?.start_time
          ? dayjs(detail.start_time)
          : dayjs(dateSelected),
        inputter_id: detail?.inputter?.id || "",
        genre_id: detail?.genre?.id || "",
        notification_time: detail?.notification_time || "",
        remarks: detail?.remarks || "",
        is_all_day: !!detail?.is_all_day,
        repeat_type: detail?.repeat_type || NONE_REPEAT_TYPE,
        repeat_end_date: detail?.repeat_end_date
          ? dayjs(detail.repeat_end_date)
          : dayjs(dateSelected).add(1, "day"),
        updateType: ONLY_UPDATE_THIS_EVENT,
      });
    } else {
      reset({
        ...defaultValue,
        start_time: dayjs(dateSelected),
        repeat_end_date: dayjs(dateSelected).add(1, "day"),
      });
    }
  }, [dateSelected, reset, item]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          border: "5px solid #d7d0c5",
          borderRadius: "10px",
          boxShadow: "24",
          maxHeight: "100%",
          overflow: "auto",
          padding: "0 0 6",
          width: "100%",
          maxWidth: "1000px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
        }}
      >
        <AppBar
          position="static"
          elevation={4}
          sx={{
            backgroundColor: "rgb(136, 160, 185)",
          }}
        >
          <Toolbar>
            <Typography variant="h6">スタッフスケジュール</Typography>
            <Button
              color="primary"
              style={{ marginLeft: "auto" }}
              onClick={handleClose}
            >
              <SvgIcon
                component={CancelPresentationIcon}
                style={{ color: "white", fontSize: 48 }}
              />
            </Button>
          </Toolbar>
        </AppBar>

        <Container
          maxWidth="md"
          sx={{
            sm: {
              paddingLeft: "24px",
              paddingRight: "24px",
            },
            width: "100%",
            marginTop: "16px",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
            <form>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="タイトル"
                    placeholder="タイトル"
                    required
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.title}
                    helperText={errors.title ? errors.title.message : ""}
                  />
                )}
              />

              <div className="field-allday">
                <Box sx={{ display: "flex", gap: "16px" }}>
                  <Controller
                    name="is_all_day"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                        }
                        label="終日"
                        labelPlacement="bottom"
                      />
                    )}
                  />
                  {isAllDay && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <Controller
                        name="start_time"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            label="開始予約時間"
                            value={field.value}
                            onChange={(date) => field.onChange(date)}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                        )}
                      />
                      <FormHelperText sx={{ color: "red" }}>
                        {errors?.start_time?.message}
                      </FormHelperText>
                    </Box>
                  )}
                </Box>

                {!isAllDay && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Controller
                      name="start_time"
                      control={control}
                      render={({ field }) => (
                        <DateTimePicker
                          label="開始予約時間"
                          value={field.value}
                          onChange={(date) => field.onChange(date)}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      )}
                    />
                    <FormHelperText sx={{ color: "red" }}>
                      {errors?.start_time?.message}
                    </FormHelperText>
                  </Box>
                )}
              </div>

              <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    width: "50%",
                  }}
                >
                  <Controller
                    name="repeat_type"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="繰り返し"
                        select
                        fullWidth
                        {...field}
                        variant="outlined"
                        margin="normal"
                      >
                        <MenuItem value={NONE_REPEAT_TYPE}>なし</MenuItem>
                        <MenuItem value={DAILY_TYPE}>
                          毎日
                        </MenuItem>
                        <MenuItem value={EVERY_DAY_OF_THE_WEEK_TYPE}>
                          毎週平日（月～金）
                        </MenuItem>
                        <MenuItem value={ONE_DAY_IN_EVERY_WEEK_TYPE}>
                          毎週　{DAY_OF_WEEK_TEXT_LARGE_ARR[dayjs(startTime).day()]}
                        </MenuItem>
                      </TextField>
                    )}
                  />
                  <FormHelperText sx={{ color: "red" }}></FormHelperText>
                </Box>
                {!!repeatType && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      marginTop: "8px",
                    }}
                  >
                    <Controller
                      name="repeat_end_date"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          label="繰り返し終了日"
                          value={field.value || null}
                          onChange={(date) => field.onChange(date)}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                            }
                          }}
                        />
                      )}
                    />
                    <FormHelperText sx={{ color: "red" }}>
                      {errors?.repeat_end_date?.message}
                    </FormHelperText>
                  </Box>
                )}
              </Box>

              <div className="field-enteredBy">
                <Controller
                  name="inputter_id"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label="入力者"
                        select
                        fullWidth
                        {...field}
                        variant="outlined"
                        margin="normal"
                      >
                        {inputterList.map((item, index) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Button
                        variant="outlined"
                        color="success"
                        style={{ marginTop: "16px", marginLeft: "24px" }}
                        onClick={() => setFillerModalIsOpen(true)}
                      >
                        <AddToPhotosOutlined />
                      </Button>
                    </>
                  )}
                />
              </div>
              {errors.inputter_id && (
                <div>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.inputter_id?.message}
                  </FormHelperText>
                </div>
              )}

              <div className="field-enteredBy">
                <Controller
                  name="genre_id"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextField
                        label="ジャンル"
                        select
                        fullWidth
                        {...field}
                        variant="outlined"
                        margin="normal"
                      >
                        {genresList.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Button
                        variant="outlined"
                        color="success"
                        style={{ marginTop: "16px", marginLeft: "24px" }}
                        onClick={() => setGenreModalIsOpen(true)}
                      >
                        <AddToPhotosOutlined />
                      </Button>
                    </>
                  )}
                />
              </div>
              {errors.genre_id && (
                <div>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {errors?.genre_id?.message}
                  </FormHelperText>
                </div>
              )}

              <Controller
                name="notification_time"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="お知らせ"
                    type="number"
                    placeholder="なし"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    {...field}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">分前</InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!/^\d*$/.test(value)) {
                        e.target.value = value.replace(/[^\d]/g, "");
                      }
                      field.onChange(e);
                    }}
                    error={!!errors.notification_time}
                    helperText={
                      errors.notification_time
                        ? errors.notification_time.message
                        : ""
                    }
                  />
                )}
              />

              <Controller
                name="remarks"
                control={control}
                render={({ field }) => (
                  <textarea
                    placeholder="メモ"
                    {...field}
                    style={{
                      marginTop: "30px",
                      height: "96px",
                      width: "100%",
                      fontSize: "18px",
                      outline: "none",
                      background: "transparent",
                      borderRadius: "5px",
                      resize: "none",
                    }}
                  />
                )}
              />

              <Box sx={{ paddingTop: "16px", paddingBottom: "16px" }}>
                {!id ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type="button"
                      variant="outlined"
                      color="success"
                      size="large"
                      fullWidth
                      style={{ maxWidth: "300px" }}
                      onClick={handleCreateRecord}
                    >
                      スケジュール確定
                    </Button>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                      type="button"
                      variant="outlined"
                      onClick={handleDuplidateEvent}
                    >
                      複製
                    </Button>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                      <Button
                        type="button"
                        variant="outlined"
                        color="secondary"
                        onClick={confirmUpdateType}
                      >
                        編集
                      </Button>
                      <Button
                        type="button"
                        variant="outlined"
                        color="error"
                        onClick={confirmDeleteType}
                      >
                        削除
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </form>
          </LocalizationProvider>
        </Container>
      </Box>

      <Modal
        open={fillerModalIsOpen}
        onClose={() => setFillerModalIsOpen(false)}
        aria-labelledby="transition-modal-title"
      >
        <PopupFillerMaster
          onClose={() => setFillerModalIsOpen(false)}
          reftchList={refetchAllData}
        />
      </Modal>
      <Modal
        open={genreModalIsOpen}
        onClose={() => setGenreModalIsOpen(false)}
        aria-labelledby="transition-modal-title"
      >
        <PopupGenreMaster
          onClose={() => setGenreModalIsOpen(false)}
          reftchList={refetchAllData}
        />
      </Modal>
      <Modal
        open={isOpenModalUpdateType}
        onClose={() => setIsOpenModalUpdateType(false)}
        aria-labelledby="transition-modal-title"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            border: "4px solid #d7d0c5",
            borderRadius: "8px",
            boxShadow: "24",
            height: "320px",
            overflow: "auto",
            paddingBottom: "6",
            width: "100%",
            maxWidth: "1000px",
            top: "100px",
            left: "50%",
            transform: "translateX(-50%)",
            position: "absolute",
          }}
        >
          <AppBar
            position="static"
            elevation={4}
            sx={{
              backgroundColor: "rgb(136, 160, 185)",
            }}
          >
            <Toolbar>
              <Typography variant="h6">リピートイベントを編集する</Typography>
            </Toolbar>
          </AppBar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              padding: "16px",
            }}
          >
            {listUpdateType.includes(ONLY_UPDATE_THIS_EVENT) && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  value={ONLY_UPDATE_THIS_EVENT}
                  checked={updateType === ONLY_UPDATE_THIS_EVENT}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setUpdateType(ONLY_UPDATE_THIS_EVENT);
                    }
                  }}
                />
                <span>現在の予定のみ編集</span>
              </div>
            )}
            {listUpdateType.includes(UPDATE_ALL_FUTURE_EVENTS) && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  value={UPDATE_ALL_FUTURE_EVENTS}
                  checked={updateType === UPDATE_ALL_FUTURE_EVENTS}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setUpdateType(UPDATE_ALL_FUTURE_EVENTS);
                    }
                  }}
                />
                <span>この予定と以降の予定を編集</span>
              </div>
            )}
            {listUpdateType.includes(UPDATE_ALL_EVENTS) && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  value={UPDATE_ALL_EVENTS}
                  checked={updateType === UPDATE_ALL_EVENTS}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setUpdateType(UPDATE_ALL_EVENTS);
                    }
                  }}
                />
                <span>すべての予定を編集</span>
              </div>
            )}
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsOpenModalUpdateType(false)}
              >
                キャンセル
              </Button>
              <Button variant="outlined" color="success" onClick={() => handleUpdateRecord()}>
                保存
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal >
      <Modal
        open={isOpenModalDeleteType}
        onClose={() => setIsOpenModalDeleteType(false)}
        aria-labelledby="transition-modal-title"
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            border: "4px solid #d7d0c5",
            borderRadius: "8px",
            boxShadow: "24",
            height: "320px",
            overflow: "auto",
            paddingBottom: "6",
            width: "100%",
            maxWidth: "1000px",
            top: "100px",
            left: "50%",
            transform: "translateX(-50%)",
            position: "absolute",
          }}
        >
          <AppBar
            position="static"
            elevation={4}
            sx={{
              backgroundColor: "rgb(136, 160, 185)",
            }}
          >
            <Toolbar>
              <Typography variant="h6">リピートイベントを削除する</Typography>
            </Toolbar>
          </AppBar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              padding: "16px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                value={ONLY_UPDATE_THIS_EVENT}
                checked={deleteType === ONLY_UPDATE_THIS_EVENT}
                onChange={(e) => {
                  if (e.target.checked) {
                    setDeleteType(ONLY_UPDATE_THIS_EVENT);
                  }
                }}
              />
              <span>現在の予定のみ削除</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                value={UPDATE_ALL_FUTURE_EVENTS}
                checked={deleteType === UPDATE_ALL_FUTURE_EVENTS}
                onChange={(e) => {
                  if (e.target.checked) {
                    setDeleteType(UPDATE_ALL_FUTURE_EVENTS);
                  }
                }}
              />
              <span>この予定と以降の予定を削除</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                value={UPDATE_ALL_EVENTS}
                checked={deleteType === UPDATE_ALL_EVENTS}
                onChange={(e) => {
                  if (e.target.checked) {
                    setDeleteType(UPDATE_ALL_EVENTS);
                  }
                }}
              />
              <span>すべての予定を削除</span>
            </div>
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsOpenModalDeleteType(false)}
              >
                キャンセル
              </Button>
              <Button variant="outlined" color="error" onClick={handleDeleteRecord}>
                保存
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default PopupEventComponent;
