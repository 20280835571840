import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  FormControl,
  Typography,
  Button,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import DatePickerComponent from "../../components/DatePicker";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import usePaginate from "../../hooks/usePaginate";
import {
  deleteTherapistCalendar,
  getListTherapist,
  getListTherapistCalendars,
  saveTherapistCalendar,
} from "../../api/therapist";
import { getListStore } from "../../api/store";
import TablePaginationLayout from "../../layouts/TablePagination";
import ModalConfirmShift from "../../features/therapistCalendar/ModalConfirmShift";
import "rc-picker/assets/index.css";
import moment from "moment";
import "./style.css";
import SearchIcon from "@mui/icons-material/Search";
import TimePickerComponent from "./TimePickerComponent";
import ModalAddMultiShift from "../../features/therapistCalendar/ModalAddMultiShift";
import { END_HOUR_TIME, START_HOUR_TIME } from "../../constants";

const weekShift = Array(10).fill({
  date_start: moment(`${START_HOUR_TIME}:00`, "HH:mm"),
  date_end: moment(`${START_HOUR_TIME}:00`, "HH:mm"),
  store_id: "",
});

const TherapistShift = () => {
  const [dateSelected, setDateSelected] = useState(dayjs(new Date()));

  const days = useMemo(() => {
    const daysOfWeek = ["日", "月", "火", "水", "木", "金", "土"];

    const getJapaneseDayOfWeek = (date) => {
      return daysOfWeek[dayjs(date).day()];
    };

    return Array.from({ length: 10 }, (_, i) => {
      const date = dateSelected.add(i, "day");
      return date.format("MM月DD日") + `(${getJapaneseDayOfWeek(date)})`;
    });
  }, [dateSelected]);

  const dateOfWeek = useMemo(() => {
    return Array.from({ length: 10 }, (_, i) =>
      dateSelected.add(i, "day").format("YYYY-MM-DD")
    );
  }, [dateSelected]);

  const TherapistRow = React.memo(
    ({
      therapist,
      therapistIndex,
      days,
      shifts,
      handleShiftChange,
      handleAddCalendar,
      dataStores,
    }) => {
      return (
        <Grid
          container
          spacing={1}
          sx={{ flexWrap: "nowrap", minWidth: "1300px", marginBottom: "10px" }}
          key={therapist.id}
        >
          <Grid item>
            <Box sx={{ width: "130px" }}>
              <a href={`/therapistshift-therapist-view/${therapist.id}`}>
                {therapist.name}
              </a>
            </Box>
          </Grid>
          {days.map((day, dayIndex) => (
            <Grid item xs key={day}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "16px",
                  gap: "4px",
                  width: "94px",
                }}
              >
                <TimePickerComponent
                  therapistIndex={therapistIndex}
                  dayIndex={dayIndex}
                  handleShiftChange={handleShiftChange}
                  name="date_start"
                  value={
                    shifts[therapistIndex][dayIndex].date_start ||
                    moment(`${START_HOUR_TIME}:00`, "HH:mm")
                  }
                />
                <TimePickerComponent
                  therapistIndex={therapistIndex}
                  dayIndex={dayIndex}
                  handleShiftChange={handleShiftChange}
                  name="date_end"
                  value={
                    shifts[therapistIndex][dayIndex].date_end ||
                    moment(`${START_HOUR_TIME}:00`, "HH:mm")
                  }
                />
                <select
                  className="select-store"
                  value={shifts[therapistIndex][dayIndex].store_id}
                  onChange={(e) =>
                    handleShiftChange(
                      therapistIndex,
                      dayIndex,
                      "store_id",
                      e.target.value
                    )
                  }
                >
                  <option value="">選択してく</option>
                  {dataStores?.data?.data?.map((store) => (
                    <option key={store.id} value={store.id}>
                      {store.store_name}
                    </option>
                  ))}
                </select>
              </Box>
            </Grid>
          ))}
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "9px",
            }}
          >
            <Button
              variant="outlined"
              sx={{ width: "130px" }}
              onClick={() => handleOpenMultiShift(therapistIndex)}
            >
              一括入力
            </Button>
            <Button
              variant="contained"
              sx={{ width: "130px" }}
              onClick={() => handleAddCalendar(therapistIndex)}
            >
              シフト確定
            </Button>
          </Grid>
        </Grid>
      );
    }
  );

  const {
    page: pageCalendar,
    perPage: perPageCalendar,
    total: totalCalendar,
    changePage: changePageCalendar,
    setTotal: setTotalCalendar,
    setPage: setPageCalendar,
    changePerPage: changePerPageCalendar,
  } = usePaginate();

  const {
    page: pageTherapist,
    perPage: perPageTherapist,
    total: totalTherapist,
    changePage: changePageTherapist,
    setTotal: setTotalTherapist,
    setPage: setPageTherapist,
    changePerPage: changePerPageTherapist,
  } = usePaginate(0, 10);

  const [keySearchTherapist, setKeySearchTherapist] = useState("");
  const [keySearchCalendar, setKeySearchCalendar] = useState("");

  const [keyTherapist, setKeyTherapist] = useState("");
  const [keyCalendar, setKeyCalendar] = useState("");

  const [therapists, setTherapists] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [shiftSelected, setShiftSelected] = useState();
  const [clickCount, setClickCount] = useState(0);

  const [isOpenMultiShift, setIsOpenMultiShift] = useState(false);
  const [selectedTherapist, setSelectedTherapist] = useState(null);

  const { data: dataStores } = useQuery(["list_stores"], () => {
    return getListStore({ per_page: 50 });
  });

  const { data: dataTherapist } = useQuery(
    ["list_therapists", pageTherapist, perPageTherapist, keyTherapist],
    () => {
      const payload = {
        name: keyTherapist || undefined,
        page: pageTherapist + 1,
        per_page: perPageTherapist,
      };
      return getListTherapist(payload);
    },
    {
      keepPreviousData: true,
    }
  );

  const { data: dataCalendars, refetch: refetchCalendars } = useQuery(
    ["list_calendars", pageCalendar, perPageCalendar, keyCalendar],
    () => {
      const payload = {
        name_therapist: keyCalendar || undefined,
        name_store: keyCalendar || undefined,
        page: pageCalendar + 1,
        per_page: perPageCalendar,
      };
      return getListTherapistCalendars(payload);
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    try {
      if (dataTherapist) {
        const { data, total } = dataTherapist.data;
        setTherapists(data);
        setTotalTherapist(total);
        setShifts(
          Array(data.length)
            .fill()
            .map(() => weekShift)
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [dataTherapist, setTotalTherapist]);

  useEffect(() => {
    try {
      if (dataCalendars) {
        setCalendars(dataCalendars.data.data);
        setTotalCalendar(dataCalendars.data.total);
      }
    } catch (error) {
      console.error(error);
    }
  }, [dataCalendars, setTotalCalendar]);

  const handleShiftChange = useCallback((row, col, field, value) => {
    setShifts((prev) => {
      const newValue = [...prev];
      newValue[row] = [...newValue[row]];
      newValue[row][col] = { ...newValue[row][col], [field]: value };
      return newValue;
    });
  }, []);

  const handleAddCalendar = useCallback(
    async (index) => {
      try {
        const therapist = therapists[index];
        const shiftTherapist = shifts[index];
        const workingDays = shiftTherapist
          .map((item, indexItem) => {
            let start = moment(
              `${dateOfWeek[indexItem]} ${moment(item.date_start).format(
                "HH:mm"
              )}`,
              "YYYY-MM-DD HH:mm"
            );
            let end = moment(
              `${dateOfWeek[indexItem]} ${moment(item.date_end).format(
                "HH:mm"
              )}`,
              "YYYY-MM-DD HH:mm"
            );

            if (start.hour() <= END_HOUR_TIME - 24) {
              start.add(1, "day");
            }
            if (end.hour() <= END_HOUR_TIME - 24) {
              end.add(1, "day");
            }

            return {
              status: 1,
              store_id: item.store_id,
              date_start: start.format("YYYY-MM-DD HH:mm"),
              date_end: end.format("YYYY-MM-DD HH:mm"),
              date: dateOfWeek[indexItem],
            };
          })
          .filter((item) => !!item.store_id);

        if (workingDays.length === 0) return;

        const params = { working_days: workingDays };
        const response = await saveTherapistCalendar(therapist.id, params);
        if (response.success) {
          refetchCalendars();
          setShifts((prev) => {
            const newValue = [...prev];
            newValue[index] = weekShift;
            return newValue;
          });
          alert("シフトが確定しました。");
        } else {
          alert("エラーが発生しました。");
        }
      } catch (error) {
        if (error.response?.status === 422) {
          const messages = error.response.data.message;
          if (messages[Object.keys(messages)[0]]) {
            alert(messages[Object.keys(messages)[0]]);
          } else {
            alert("エラーが発生しました。");
          }
        } else {
          alert("エラーが発生しました。");
        }
      }
    },
    [dateOfWeek, refetchCalendars, shifts, therapists]
  );

  const handleSearchTherapist = useCallback(() => {
    setKeyTherapist(keySearchTherapist);
    setPageTherapist(0);
  }, [keySearchTherapist, setPageTherapist]);

  const onKeyDownSearchTherapist = useCallback(
    (e) => {
      if (e.key === "Enter") {
        handleSearchTherapist();
      }
    },
    [handleSearchTherapist]
  );

  const handleSearchCalendar = useCallback(() => {
    setKeyCalendar(keySearchCalendar);
    setPageCalendar(0);
  }, [keySearchCalendar, setPageCalendar]);

  const onKeyDownSearchCalendar = useCallback(
    (e) => {
      if (e.key === "Enter") {
        handleSearchCalendar();
      }
    },
    [handleSearchCalendar]
  );

  const handleOpenModal = useCallback(
    (item) => {
      setClickCount((prev) => prev + 1);
      if (clickCount + 1 >= 2) {
        setShiftSelected(item);
        setIsOpenModal(true);
        setClickCount(0);
      }

      setTimeout(() => {
        setClickCount(0);
      }, 300);
    },
    [clickCount]
  );

  const handleOpenMultiShift = useCallback(
    (therapistIndex) => {
      setSelectedTherapist(therapists[therapistIndex]);
      setIsOpenMultiShift(true);
    },
    [therapists]
  );

  const handleConfirmShift = useCallback(async () => {
    try {
      const workingDays = [
        {
          status: 1,
          store_id: shiftSelected.store_id,
          date_start: shiftSelected.date_start,
          date_end: shiftSelected.date_end,
          date: shiftSelected.date,
        },
      ];
      const params = { working_days: workingDays };
      const response = await saveTherapistCalendar(
        shiftSelected.therapist_id,
        params
      );
      if (response.success) {
        refetchCalendars();
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      if (error.response?.status === 422) {
        const messages = error.response.data.message;
        if (messages[Object.keys(messages)[0]]) {
          alert(messages[Object.keys(messages)[0]]);
        } else {
          alert("エラーが発生しました。");
        }
      } else {
        alert("エラーが発生しました。");
      }
    } finally {
      setIsOpenModal(false);
    }
  }, [shiftSelected, refetchCalendars]);

  const handleDeleteTherapistCalendar = useCallback(
    async (id) => {
      if (
        !window.confirm(
          "このカレンダーを削除すると、このカレンダー内のすべての予約が削除されます。本当に削除しますか？"
        )
      ) {
        return;
      }
      try {
        const response = await deleteTherapistCalendar(id);
        if (response.success) {
          refetchCalendars();
        } else {
          alert("エラーが発生しました。");
        }
      } catch (error) {
        alert("エラーが発生しました。");
      } finally {
        setIsOpenModal(false);
      }
    },
    [refetchCalendars]
  );

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      <Container
        maxWidth="lg"
        sx={{ marginTop: "32px", maxWidth: "1500px !important" }}
      >
        <Typography
          component="p"
          sx={{
            fontSize: {
              xs: 20,
              md: 28,
            },
            color: "#000",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            marginBottom: "16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid black",
            textAlign: "left",
          }}
        >
          セラピストのシフト設定
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <Button
                variant="outlined"
                onClick={() =>
                  setDateSelected((prev) => prev.subtract(10, "day"))
                }
              >
                前週
              </Button>
            </Grid>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <FormControl>
                <DatePickerComponent
                  currentDate={dateSelected}
                  onDateChange={setDateSelected}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <Button
                variant="outlined"
                onClick={() => setDateSelected((prev) => prev.add(10, "day"))}
              >
                来週
              </Button>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flexGrow: 1,
                marginLeft: "auto",
              }}
            >
              <FormControl
                className="MuiFormControl-marginNormal"
                sx={{ margin: "16px 0 8px" }}
              >
                <InputBase
                  className="MuiInput-root MuiInput-underline"
                  id="search_key"
                  name="search_key"
                  placeholder="検索"
                  type="text"
                  value={keySearchTherapist}
                  onChange={(e) => setKeySearchTherapist(e.target.value)}
                  inputProps={{ className: "MuiInputBase-input css-mnn31" }}
                  aria-invalid="false"
                  onKeyDown={onKeyDownSearchTherapist}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon
                        sx={{ cursor: "pointer" }}
                        onClick={handleSearchTherapist}
                      />
                    </InputAdornment>
                  }
                  sx={{
                    marginTop: "16px",
                    "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                      {
                        borderBottom: "1px solid rgb(0, 0, 0, 0.42)",
                        left: 0,
                        bottom: 0,
                        content: '""',
                        position: "absolute",
                        right: 0,
                        transition:
                          "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        pointerEvents: "none",
                      },
                    "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                      {
                        borderBottom: "2px solid rgb(0, 0, 0)",
                      },
                    "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::after":
                      {
                        borderBottom: "2px solid rgb(25, 118, 210)",
                        left: 0,
                        bottom: 0,
                        content: '""',
                        position: "absolute",
                        right: 0,
                        transform: "scale(0)",
                        transition:
                          "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                        pointerEvents: "none",
                      },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Paper sx={{ padding: "16px", overflowX: "auto" }}>
          <Grid
            container
            spacing={1}
            sx={{
              flexWrap: "nowrap",
              minWidth: "1300px",
              marginBottom: "10px",
            }}
          >
            <Grid item xs>
              <Typography align="center" sx={{ width: "130px" }}>
                名前
              </Typography>
            </Grid>
            {days.map((day) => (
              <Grid key={day} item xs>
                <Typography fontSize={14} sx={{ width: "94px" }} align="center">
                  {day}
                </Typography>
              </Grid>
            ))}
            <Grid item xs>
              <Typography
                align="center"
                sx={{ paddingLeft: "8px", width: "130px" }}
              >
                操作
              </Typography>
            </Grid>
          </Grid>

          {therapists.map((therapist, therapistIndex) => (
            <TherapistRow
              key={therapist.id}
              therapist={therapist}
              therapistIndex={therapistIndex}
              days={days}
              shifts={shifts}
              handleShiftChange={handleShiftChange}
              handleAddCalendar={handleAddCalendar}
              dataStores={dataStores}
            />
          ))}
          {therapists.length === 0 && (
            <Typography align="center" marginTop="20px">
              該当データがありません。
            </Typography>
          )}
          <TablePaginationLayout
            rowsPerPage={perPageTherapist}
            total={totalTherapist}
            page={pageTherapist}
            rowsPerPageOptions={[10, 20, 50]}
            onPageChange={changePageTherapist}
            handleChangeRowsPerPage={changePerPageTherapist}
          />
        </Paper>

        <Box sx={{ marginTop: "32px" }}>
          <Typography
            component="p"
            sx={{
              fontSize: {
                xs: 20,
                md: 28,
              },
              color: "#000",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "24px",
              letterSpacing: "3px",
              marginBottom: "16px",
              textDecoration: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            確定したシフト
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "16px",
            }}
          >
            <FormControl sx={{ width: "300px" }}>
              <InputBase
                className="MuiInput-root MuiInput-underline"
                placeholder="検索"
                value={keySearchCalendar}
                onChange={(e) => setKeySearchCalendar(e.target.value)}
                onKeyDown={onKeyDownSearchCalendar}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon
                      sx={{ cursor: "pointer" }}
                      onClick={handleSearchCalendar}
                    />
                  </InputAdornment>
                }
                sx={{
                  marginLeft: "auto",
                  "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                    {
                      borderBottom: "1px solid rgb(0, 0, 0, 0.42)",
                      left: 0,
                      bottom: 0,
                      content: '""',
                      position: "absolute",
                      right: 0,
                      transition:
                        "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                      pointerEvents: "none",
                    },
                  "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                    {
                      borderBottom: "2px solid rgb(0, 0, 0)",
                    },
                  "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::after":
                    {
                      borderBottom: "2px solid rgb(25, 118, 210)",
                      left: 0,
                      bottom: 0,
                      content: '""',
                      position: "absolute",
                      right: 0,
                      transform: "scale(0)",
                      transition:
                        "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                      pointerEvents: "none",
                    },
                }}
              />
            </FormControl>
          </Box>
          <Paper sx={{ padding: "16px" }}>
            <TableContainer>
              <Table aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">セラピスト名</TableCell>
                    <TableCell align="center">店舗</TableCell>
                    <TableCell align="center">開始時間</TableCell>
                    <TableCell align="center">終了時間</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calendars.map(
                    (item, index) =>
                      !!item.therapist && (
                        <TableRow
                          key={item.id}
                          className="MuiTableRow-hover"
                          onClick={() => handleOpenModal(item)}
                        >
                          <TableCell align="center">{item.id}</TableCell>
                          <TableCell align="center">
                            {item.therapist.name}
                          </TableCell>
                          <TableCell align="center">
                            {item?.store?.store_name}
                          </TableCell>
                          <TableCell align="center">
                            {item.date_start}
                          </TableCell>
                          <TableCell align="center">{item.date_end}</TableCell>
                        </TableRow>
                      )
                  )}
                  {calendars.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Typography>該当データがありません。</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={perPageCalendar}
              total={totalCalendar}
              page={pageCalendar}
              rowsPerPageOptions={[50, 100, 125]}
              onPageChange={changePageCalendar}
              handleChangeRowsPerPage={changePerPageCalendar}
            />
          </Paper>
        </Box>

        <ModalConfirmShift
          open={isOpenModal && !!shiftSelected}
          handleClose={() => {
            setIsOpenModal(false);
            setShiftSelected(null);
          }}
          item={shiftSelected}
          setItem={setShiftSelected}
          stores={dataStores?.data?.data}
          onConfirmShift={handleConfirmShift}
          onDeleteShift={() => handleDeleteTherapistCalendar(shiftSelected.id)}
        />

        <ModalAddMultiShift
          open={isOpenMultiShift}
          handleClose={() => setIsOpenMultiShift(false)}
          dateSelected={dateSelected}
          stores={dataStores?.data?.data || []}
          refetch={refetchCalendars}
          therapist={selectedTherapist}
        />
      </Container>
    </>
  );
};

export default TherapistShift;
