import { useCallback, useEffect, useState } from "react";
import { getListTherapistNominations, updateTherapistManagement } from "../../api/therapistNomination";
import { useQuery } from "react-query";

const useTherapistNomination = (therapistId, onSave) => {
  const [therapistNominations, setTherapistNominations] = useState([]);
  const [errors, setErrors] = useState([]);

  const { data: therapistNominationsData, refetch: refetchTherapistNominations } = useQuery(
    ["therapistNominations", therapistId],
    () => {
      return getListTherapistNominations(therapistId);
    },
    {
      enabled: !!therapistId,
    },
  );

  const handleChange = useCallback((e, id) => {
    const { name, value } = e.target;
    const newTherapistNominations = therapistNominations.map((nomination) => {
      if (nomination.nomination_id === id) {
        return {
          ...nomination,
          [name]: value,
        };
      }
      return nomination;
    });
    setTherapistNominations(newTherapistNominations);
  }, [therapistNominations]);

  const handleToggle = useCallback((e, id) => {
    const checked = e.target.checked;

    const newTherapistNominations = therapistNominations.map((nomination) => {
      if (nomination.nomination_id === id) {
        return {
          ...nomination,
          therapist_amount: checked ? "" : null,
        };
      }
      return nomination;
    });
    setTherapistNominations(newTherapistNominations);
  }, [therapistNominations]);

  const handleSave = useCallback(async () => {
    try {
      const params = therapistNominations.map((nomination) => {
        return {
          nomination_id: nomination.nomination_id,
          nomination_cost: nomination.nomination_cost,
          therapist_amount: nomination.therapist_amount,
        };
      });

      const response = await updateTherapistManagement(therapistId, {
        therapist_nominations: params
      });

      if (response && response.success) {
        refetchTherapistNominations();
        onSave();
        alert("保存しました。");
      } else {
        alert("エラーが発生しました。");
        return false
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const messages = error.response.data.message;
        const newErrors = [];
        for (const [key, value] of Object.entries(messages)) {
          const [paramName, index, keyParam] = key.split(".");
          if (paramName === "therapist_nominations" && key) {
            newErrors[index] = {
              ...newErrors[index],
              [keyParam]: value
            };
          }
        }

        setErrors(newErrors);
      } else {
        alert("エラーが発生しました。");
      }
      return false
    }
  }, [therapistNominations, therapistId, refetchTherapistNominations, onSave]);

  useEffect(() => {
    if (therapistNominationsData && therapistNominationsData.data) {
      const newTherapistNominations = therapistNominationsData.data.map((nomination) => {
        return {
          ...nomination,
          nomination_cost: nomination.nomination_cost ? parseInt(nomination.nomination_cost) : null,
          therapist_amount: nomination.therapist_amount ? parseInt(nomination.therapist_amount) : null,
        };
      });
      setTherapistNominations(newTherapistNominations);
    }
  }, [therapistNominationsData]);

  return {
    therapistNominations,
    errors,
    handleChange,
    handleSave,
    handleToggle,
  };
}

export default useTherapistNomination;
