import React from 'react';
import useTherapistNomination from '../../features/therapistNomination';
import PopupComponent from '../../layouts/Popup';
import { Button, Grid, Switch, TextField, Typography } from '@mui/material';

const PopupTherapistNomination = ({
  therapist,
  isOpen,
  onClose,
  onSubmit = () => { },
}) => {
  const onSave = () => {
    onClose();
    onSubmit();
  };

  const {
    therapistNominations,
    errors,
    handleChange,
    handleSave,
    handleToggle,
  } = useTherapistNomination(therapist.id, onSave);

  return (
    <PopupComponent open={isOpen} onClose={onClose} width={900}>
      <div style={{ textAlign: 'left', borderBottom: '1px solid' }}>{therapist.name}</div>
      {therapistNominations.map((nomination, index) => (
        <Grid container key={nomination.nomination_id} spacing={1} style={{ marginTop: 20, alignItems: "center" }}>
          <Grid item style={{ display: "flex", alignItems: "center" }} sx={12} sm={6}>
            <div style={{ display: 'flex', width: "150px", wordBreak: "break-all", flexDirection: 'column', alignItems: 'center' }}>
              {!!nomination.therapist_id ?
                <span style={{ width: '100%', textAlign: 'center', color: '#ff3838' }}>custom</span>
                : <span style={{ width: '100%', textAlign: 'center', color: '#5858ff' }}>default</span>}
              <span>{nomination.name}</span>
            </div>
            <TextField
              style={{ width: 180 }}
              label="お客様へ請求する金額"
              name="nomination_cost"
              value={nomination.nomination_cost}
              onChange={(e) => handleChange(e, nomination.nomination_id)}
              required
              type="number"
              onKeyDown={(e) => {
                if (
                  e.key === "e" ||
                  e.key === "E" ||
                  e.key === "-" ||
                  e.key === "+" ||
                  e.key === "."
                ) {
                  e.preventDefault();
                }
              }}
              error={!!errors?.[index]?.nomination_cost}
              helperText={errors?.[index]?.nomination_cost || ""}
            />
            <Grid item style={{ marginLeft: 10, height: '56px', borderLeft: '1px solid', padding: '0 10px' }} sx={{ display: { xs: "none", sm: "block" } }}></Grid>
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }} sx={12} sm={6}>
            <Switch checked={nomination.therapist_amount !== null} onChange={(e) => handleToggle(e, nomination.nomination_id)} />
            {nomination.therapist_amount !== null ? (
              <TextField
                fullWidth
                label="セラピストの給料"
                name="therapist_amount"
                value={nomination.therapist_amount || ""}
                onChange={(e) => handleChange(e, nomination.nomination_id)}
                required
                type="number"
                onKeyDown={(e) => {
                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "-" ||
                    e.key === "+" ||
                    e.key === "."
                  ) {
                    e.preventDefault();
                  }
                }}
                sx={{ marginLeft: 2 }}
                error={!!errors?.[index]?.therapist_amount}
                helperText={errors?.[index]?.therapist_amount || ""}
              />
            ) : <Typography>1,000円～本指名５本ごとに500円アップ</Typography>}
          </Grid>
        </Grid>
      ))
      }
      <Button
        type="button"
        fullWidth
        variant="outlined"
        color="success"
        style={{ marginTop: 20 }}
        onClick={handleSave}
      >
        保存
      </Button>
    </PopupComponent >
  );
};

export default PopupTherapistNomination;
