import { useCallback, useEffect, useRef, useState } from "react";
import {
  createOption,
  deleteOption,
  downBottomOrderNumber,
  downOrderNumber,
  getListOption,
  updateOption,
  upOrderNumber,
  upTopOrderNumber,
} from "../../api/option";
import { useQuery } from "react-query";
import { isNumeric } from "../../utils";

const useOptionMaster = () => {
  const [arrOptions, setArrOptions] = useState([]);
  const [activeSortColumn, setActiveSortColumn] = useState(0);
  const [sortColumnDirection, setSortColumnDirection] = useState("desc");
  const [sortedData, setSortedData] = useState([]);
  const [newOption, setNewOption] = useState({
    name: "",
    cost: "",
    therapist_amount: "",
    remarks: "",
  });

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const [editOption, setEditOption] = useState(null);
  const [optionToDelete, setOptionToDelete] = useState(null);
  const [isPopupOpenUpdate, setIsPopupOpenUpdate] = useState(false);
  const [isPopupOpenDelete, setIsPopupOpenDelete] = useState(false);
  const [focusState, setFocusState] = useState({
    name: false,
    cost: false,
  });

  const nameInputRef = useRef(null);

  const [errors, setErrors] = useState({
    name: "",
    cost: "",
    therapist_amount: "",
    remarks: "",
  });

  const [errorsUpdate, setErrorsUpdate] = useState({
    name: "",
    cost: "",
    therapist_amount: "",
    remarks: "",
  });

  const { data, refetch } = useQuery(
    ["options", page, rowsPerPage],
    () => {
      const params = {
        page: page + 1,
        per_page: rowsPerPage,
      };
      return getListOption(params);
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (data) {
      setArrOptions(transformOptionData(data.data.data));
      setTotal(data.data.total);
    }
  }, [data]);

  useEffect(() => {
    setSortedData(arrOptions.sort((a, b) => b.order_number - a.order_number));
  }, [arrOptions]);

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const transformOptionData = (data) => {
    return data.map((option) => ({
      order_number: option.order_number,
      id: option.id.toString(),
      name: option.name,
      cost: parseInt(option.cost),
      therapist_amount: parseInt(option.therapist_amount) || 0,
      remarks: option.remarks || "",
    }));
  };

  const handleSort = useCallback((columnIndex) => {
    const isSorted = activeSortColumn === columnIndex;
    const direction = isSorted
      ? sortColumnDirection === "asc"
        ? "desc"
        : "asc"
      : "desc";
    const sorted = [...sortedData].sort((a, b) => {
      let aValue = a[Object.keys(a)[columnIndex]];
      let bValue = b[Object.keys(b)[columnIndex]];

      // if aValue and bValue are numberic, sort parseInt
      if (isNumeric(aValue) && isNumeric(bValue)) {
        aValue = parseInt(aValue);
        bValue = parseInt(bValue);
      }

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setSortedData(sorted);
    setActiveSortColumn(columnIndex);
    setSortColumnDirection(direction);
  }, [activeSortColumn, sortColumnDirection, sortedData])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewOption({ ...newOption, [name]: value });
    setErrors({ name: "", cost: "",therapist_amount:"", remarks: "" });
  };

  const handleAddOption = async () => {
    try {
      const newOptionData = {
        name: newOption.name,
        cost: newOption.cost,
        therapist_amount: newOption.therapist_amount,
        remarks: newOption.remarks,
      };

      const response = await createOption(newOptionData);

      if (response && response.data) {
        refetch();
        setNewOption({ name: "", cost: "",therapist_amount:"", remarks: "" });
        setErrors({ name: "", cost: "",therapist_amount:"", remarks: "" });
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        setErrors((prev) => ({
          ...prev,
          ...fieldErrors,
        }));
      }
      else {
        alert("エラーが発生しました。");
      }
    }
  };

  const handleUpdateOption = async (updatedOption) => {
    try {
      const { id, name, cost,therapist_amount, remarks } = updatedOption;
      const updatedData = {
        name,
        cost: cost,
        therapist_amount: therapist_amount,
        remarks: remarks,
      };

      const response = await updateOption(id, updatedData);

      if (response && response.data) {
        refetch();
        return true;
      } else {
        alert("エラーが発生しました。");
        return false;
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        setErrorsUpdate((prev) => ({
          ...prev,
          ...fieldErrors,
        }));
      }
      else {
        alert("エラーが発生しました。");
      }
      return false;
    }
  };

  const handleDeleteOption = async (id) => {
    try {
      const response = await deleteOption(id);

      if (response.success) {
        refetch();
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleUpOrderNumber = useCallback(async (id) => {
      try {
        const response = await upOrderNumber(id);
        refetch();
        if (activeSortColumn !== 0) {
          handleSort(0); // sort by order number
        }
        alert(response?.message);
      } catch (error) {
        alert(error?.response?.data?.message || "エラーが発生しました。");
      }
    }, [activeSortColumn, handleSort, refetch]);
  
    const handleDownOrderNumber = useCallback(async (id) => {
      try {
        const response = await downOrderNumber(id);
        refetch();
        if (activeSortColumn !== 0) {
          handleSort(0); // sort by order number
        }
        alert(response?.message);
      } catch (error) {
        alert(error?.response?.data?.message || "エラーが発生しました。");
      }
    }, [activeSortColumn, handleSort, refetch]);
  
    const handleUpTopOrderNumber = useCallback(async (id) => {
      try {
        const response = await upTopOrderNumber(id);
        refetch();
        if (activeSortColumn !== 0) {
          handleSort(0); // sort by order number
        }
        alert(response?.message);
      } catch (error) {
        alert(error?.response?.data?.message || "エラーが発生しました。");
      }
    }, [activeSortColumn, handleSort, refetch]);
  
    const handleDownBottomOrderNumber = useCallback(async (id) => {
      try {
        const response = await downBottomOrderNumber(id);
        refetch();
        if (activeSortColumn !== 0) {
          handleSort(0); // sort by order number
        }
        alert(response?.message);
      } catch (error) {
        alert(error?.response?.data?.message || "エラーが発生しました。");
      }
    }, [activeSortColumn, handleSort, refetch]);

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    const maxPage = Math.floor(total / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(Math.min(page, maxPage));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleFocus = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: true,
    }));
  };

  const handleBlur = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: false,
    }));
  };

  const handleEditClick = (option) => {
    setEditOption(option);
    setIsPopupOpenUpdate(true);
  };

  const handleDeleteClick = (option) => {
    setOptionToDelete(option);
    setIsPopupOpenDelete(true);
  }

  const handleClosePopup = () => {
    setIsPopupOpenUpdate(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditOption((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrorsUpdate({ name: "", cost: "", therapist_amount:"", remarks: "" });
  };

  return {
    arrOptions,
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    newOption,
    rowsPerPage,
    page,
    total,
    editOption,
    optionToDelete,
    isPopupOpenUpdate,
    isPopupOpenDelete,
    focusState,
    nameInputRef,
    errors,
    errorsUpdate,
    setIsPopupOpenDelete,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleInputChange,
    handleAddOption,
    handleUpdateOption,
    handleDeleteOption,
    handleFocus,
    handleBlur,
    handleEditClick,
    handleDeleteClick,
    handleClosePopup,
    handleChange,
    handleUpOrderNumber,
    handleDownOrderNumber,
    handleUpTopOrderNumber,
    handleDownBottomOrderNumber,
  };
};

export default useOptionMaster;
