import { sendDelete, sendGet, sendPost, sendPut } from "./axios";

export const getListOption = (params) => sendGet("/options", params);
export const createOption = (params) => sendPost("/options", params);

export const updateOption = (id, params) =>
  sendPut(`/options/${id}`, params);

export const deleteOption = (id) => sendDelete(`/options/${id}`);

export const upOrderNumber = (id) =>
  sendPost(`/options/${id}/up-order-number`);

export const downOrderNumber = (id) =>
  sendPost(`/options/${id}/down-order-number`);

export const upTopOrderNumber = (id) =>
  sendPost(`/options/${id}/uptop-order-number`);

export const downBottomOrderNumber = (id) =>
  sendPost(`/options/${id}/downbot-order-number`);
