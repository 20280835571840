import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  overflow: "hidden",
  paddingBottom: "16px",
};

const ModalConfirmShift = ({
  open,
  handleClose,
  item,
  setItem,
  stores,
  onConfirmShift,
  onDeleteShift,
}) => {
  const onChangeItem = (field, newValue) => {
    setItem((prev) => {
      return { ...prev, [field]: newValue };
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            backgroundColor: "rgb(136, 160, 185)",
            color: "white",
            padding: "16px",
            boxShadow:
              " rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px",
          }}
        >
          シフト変更と削除
        </Typography>

        <Box
          sx={{
            padding: "16px 48px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              セラピスト名
            </Typography>
            <Typography>{item?.therapist?.name}</Typography>
          </Box>

          <Box>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              店舗
            </Typography>
            <Typography>{item?.store?.store_name}</Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              開始時間
            </Typography>
            <TextField
              type="datetime-local"
              value={item?.date_start}
              onChange={(e) => onChangeItem("date_start", e.target.value)}
              sx={{ width: "100%" }}
              inputProps={{
                step: 60,
                sx: {
                  fontSize: "14px",
                  padding: "8px 16px",
                },
              }}
            />
          </Box>

          <Box>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              終了時間
            </Typography>
            <TextField
              type="datetime-local"
              value={item?.date_end}
              onChange={(e) => onChangeItem("date_end", e.target.value)}
              sx={{ width: "100%" }}
              inputProps={{
                step: 60,
                sx: {
                  fontSize: "14px",
                  padding: "8px 16px",
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              justifyContent: "center",
              gap: "4px",
              marginTop: "32px",
            }}
          >
            <Button variant="outlined" color="success" onClick={onConfirmShift}>
              編集
            </Button>
            <Button variant="outlined" color="error" onClick={onDeleteShift}>
              削除
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalConfirmShift;
