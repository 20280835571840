import { Box, Divider, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

function MonthlyReverseCountComponent({
  days,
  currentDate,
  updateCurrentDate,
  activeDayIndex,
  setActiveDayIndex,
  type,
}) {
  const [currentDateAsDate] = useState(dayjs(currentDate));

  useEffect(() => {
    const todayIndex = days.findIndex(
      (day) => parseInt(day.date) === currentDateAsDate.date()
    );
    if (todayIndex !== -1) {
      setActiveDayIndex(todayIndex);
    }
  }, [currentDateAsDate, days, setActiveDayIndex]);

  const handleDayClick = (index, day) => {
    setActiveDayIndex(index);
    const selectedDate = days[index].date;
    updateCurrentDate(selectedDate);
  };

  const getDayStyles = (date) => {
    const dayOfWeek = dayjs(date).day();
    if (type === "reservation") {
      switch (dayOfWeek) {
        case 6:
          return { color: "blue" };
        case 0:
          return { color: "red" };
        default:
          return { color: "inherit" };
      }
    } else if (type === "dailyReport") {
      switch (dayOfWeek) {
        case 6:
          return { backgroundColor: "rgb(206, 236, 245)" };
        case 0:
          return { backgroundColor: "rgb(248, 224, 224)" };
        default:
          return { backgroundColor: "inherit" };
      }
    }
    return {};
  };

  return (
    <Paper
      sx={{
        backgroundColor: "rgb(255, 255, 255)",
        color: "rgba(0, 0, 0, 0.87)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "4px",
        boxShadow:
          "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
        marginTop: "40px",
        overflow: "auto",
      }}
      className="monthly-reserve-count"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {days.map((day, index) => (
          <React.Fragment key={index}>
            <Box
              className={`cell-day day-of-week-${day.dayOfWeek} ${type === "reservation" && activeDayIndex === index
                ? "active-day"
                : ""
                } `}
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                borderLeft:
                  index > 0 ? "1px solid rgba(0, 0, 0, 0.12)" : "none",
              }}
              onClick={() => handleDayClick(index, day)}
            >
              <Box className="count-reserve-day MuiBox-root ">
                {day.reserveCount}
              </Box>
              <Typography
                className={`text-day ${day.isToday ? "today" : ""} ${type === "dailyReport" && activeDayIndex === index
                  ? "active-day"
                  : ""
                  }`}
                sx={getDayStyles(day.dateFormat)}
              >
                {day.date}
              </Typography>
            </Box>
            {index < days.length - 1 && (
              <Divider className=" MuiDivider-vertical MuiDivider-flexItem " />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Paper>
  );
}

export default MonthlyReverseCountComponent;
