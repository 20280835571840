import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import DatePickerComponent from "../../components/DatePicker";
import MonthlyReverseCountComponent from "../../components/MonthlyReverseCount";
import { useQuery, useQueryClient } from "react-query";
import usePaginate from "../../hooks/usePaginate";
import {
  exportCsvDailyReport,
  getListDailyReport,
} from "../../api/dailyReport";
import TablePaginationLayout from "../../layouts/TablePagination";
import { downloadCSV } from "../../utils";
import { deleteMultipleOrders, getCountOrders } from "../../api/orders";
import { iconDownload, iconRedDelete, iconWhiteDelete } from "../../assets";

const styleTableHead = {
  backgroundColor: "#000",
  color: "#fff",
  minWidth: "100px",
  textAlign: "center",
  verticalAlign: "middle",
  whiteSpace: "pre",
  paddingLeft: "3px",
  paddingRight: "3px",
};

const styleTableSort = {
  color: "#fff",
  "&:hover": {
    color: "#fff",
    opacity: 0.8,
  },
  "&.Mui-active": {
    color: "#fff",
  },
  "&.Mui-active .MuiTableSortLabel-icon": {
    color: "#fff",
  },
};

const styleTableBody = {
  ...styleTableHead,
  color: "#000",
  backgroundColor: "transparent",
};

// const dailyReportTherapist1Fields = [
//   { label: "セラピスト", value: "therapist_name" },
//   { label: "場所", value: "store_name" },
//   { label: "待機時間", value: "waiting_time", unit: "分" },
// ];

// const dailyReportTherapist2Fields = [
//   { label: "合計", value: "total_nominations", unit: "" },
//   { label: "セラピスト報酬", value: "total_amount_paid", unit: "円" },
//   { label: "未払い金", value: "unpaid_amount", unit: "円" },
//   { label: "封筒入金額", value: "envelope_deposit_amount", unit: "円" },
//   { label: "封筒チェック", value: "envelope_check" },
//   { label: "備考", value: "remarks" },
// ];

const DailyReport = () => {
  const queryClient = useQueryClient();

  const [dateSelected, setDateSelected] = useState(dayjs(new Date()));
  const [activeDayIndex, setActiveDayIndex] = useState(null);

  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [dailyReportFields, setDailyReportFields] = useState([]);
  const [dailyReportList, setDailyReportList] = useState([]);
  const { page, perPage, total, changePage, setTotal, changePerPage } =
    usePaginate(0, 10);

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [orderSelectedDelete, setOrderSelectedDelete] = useState([]);

  const [activeSortColumn, setActiveSortColumn] = useState(2); // default sort by date
  const [sortColumnDirection, setSortColumnDirection] = useState("asc");

  const updateCurrentDate = (newDate) => {
    const updatedDate = dateSelected.set("date", newDate);
    setDateSelected(updatedDate);
  };

  const { data: dataDailyReport, refetch: refetchDailyReport } = useQuery(
    [
      "list_daily_report",
      dateSelected,
      page,
      perPage,
      sortColumnDirection,
      activeSortColumn,
    ],
    () => {
      return getListDailyReport({
        date: dateSelected.format("YYYY-MM-DD"),
        per_page: perPage,
        page: page ? page + 1 : 1,
        sort: sortColumnDirection,
        sort_by: activeSortColumn === 3 ? "therapist_name" : "date_start",
      });
    },
    {
      keepPreviousData: true,
    }
  );

  const { data: dataCountOrders, refetch: refetchCountOrders } = useQuery(
    ["count_order_by_date", dateSelected.format("YYYY-MM-DD")],
    () => {
      const payload = {
        // start of month
        start_date: dateSelected.startOf("month").format("YYYY-MM-DD"),
        // end of month
        end_date: dateSelected.endOf("month").format("YYYY-MM-DD"),
      };
      return getCountOrders(payload);
    },
    {
      keepPreviousData: true,
    }
  );

  const daysInMonth = useMemo(() => {
    const currentMonth = dateSelected.month();
    const currentYear = dateSelected.year();
    const daysInCurrentMonth = dayjs(
      `${currentYear}-${currentMonth + 1}-01`
    ).daysInMonth();
    const daysArray = [];
    for (let i = 1; i <= daysInCurrentMonth; i++) {
      const date = dayjs(`${currentYear}-${currentMonth + 1}-${i}`).format(
        "YYYY-MM-DD"
      );
      // find reserve count
      const reserveCount = dataCountOrders?.data?.find(
        (item) => item.date === date
      );

      daysArray.push({
        dayOfWeek: i,
        isActive: false,
        reserveCount: reserveCount?.total_orders || 0,
        date: i,
        dateFormat: date,
        isToday: date === dayjs().format("YYYY-MM-DD"),
      });
    }

    return daysArray;
  }, [dataCountOrders, dateSelected]);

  const handleSort = useCallback(
    (columnIndex) => {
      const isSorted = activeSortColumn === columnIndex;
      let direction = isSorted
        ? sortColumnDirection === "asc"
          ? "desc"
          : "asc"
        : "desc";
      setActiveSortColumn(columnIndex);
      setSortColumnDirection(direction);
      changePage(0);
    },
    [activeSortColumn, sortColumnDirection, changePage]
  );

  const handleExportCSV = useCallback(async () => {
    if (isLoadingExport) return;
    try {
      setIsLoadingExport(true);
      const response = await exportCsvDailyReport({
        date: dateSelected.format("YYYY-MM-DD"),
        sort: sortColumnDirection,
        sort_by: activeSortColumn === 3 ? "therapist_name" : "date_start",
      });
      if (response?.data?.data && response?.data?.columns) {
        downloadCSV(
          response.data.columns,
          response.data.data,
          "daily_report" + dateSelected.format("YYYY-MM-DD")
        );
      } else {
        alert("エクスポートに失敗しました。");
      }
      setIsLoadingExport(false);
    } catch (error) {
      alert("エクスポートに失敗しました。");
      setIsLoadingExport(false);
    }
  }, [dateSelected, isLoadingExport, activeSortColumn, sortColumnDirection]);

  const handleSelectionDelete = useCallback(async () => {
    if (isLoadingDelete) return;
    if (orderSelectedDelete.length === 0) {
      alert("削除する予約を選択してください。");
      return;
    } else {
      // show dialog confirm delete
      if (window.confirm("選択した予約を削除しますか？")) {
        setIsLoadingDelete(true);
        const ids = orderSelectedDelete.map((e) => e[0]);
        const params = { ids: ids };
        try {
          const response = await deleteMultipleOrders(params);
          if (response.success) {
            queryClient.invalidateQueries("list_daily_report");
            queryClient.invalidateQueries("count_order_by_date");
            await refetchDailyReport();
            await refetchCountOrders();
            setOrderSelectedDelete([]);
            alert("削除しました。");
          } else {
            alert("削除に失敗しました。");
          }
        } catch (error) {
          alert("削除に失敗しました。");
        } finally {
          setIsLoadingDelete(false);
        }
      }
    }
  }, [
    orderSelectedDelete,
    isLoadingDelete,
    refetchDailyReport,
    refetchCountOrders,
    queryClient,
  ]);

  const handleBulkDelete = useCallback(async () => {
    if (isLoadingDelete) return;
    // show dialog confirm delete all
    if (window.confirm("この日のすべての予約を削除しますか？")) {
      setIsLoadingDelete(true);
      const params = {
        start_date: dateSelected.format("YYYY-MM-DD"),
        end_date: dateSelected.format("YYYY-MM-DD"),
      };
      try {
        const response = await deleteMultipleOrders(params);
        if (response.success) {
          queryClient.invalidateQueries("list_daily_report");
          queryClient.invalidateQueries("count_order_by_date");
          await refetchDailyReport();
          await refetchCountOrders();
          alert("削除しました。");
        } else {
          alert("削除に失敗しました。");
        }
      } catch (error) {
        alert("削除に失敗しました。");
      } finally {
        setIsLoadingDelete(false);
      }
    }
  }, [
    isLoadingDelete,
    refetchDailyReport,
    refetchCountOrders,
    queryClient,
    dateSelected,
  ]);

  useEffect(() => {
    try {
      if (dataDailyReport) {
        setDailyReportFields(dataDailyReport?.data?.columns || []);
        var data = dataDailyReport?.data?.data || [];
        setDailyReportList(data);
        setTotal(dataDailyReport?.data?.total);
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDailyReport]);

  useEffect(() => {
    const todayIndex = daysInMonth.findIndex(
      (day) => parseInt(day.date) === dateSelected.date()
    );
    if (todayIndex !== -1) {
      setActiveDayIndex(todayIndex);
    }
  }, [dateSelected, daysInMonth]);

  return (
    <>
      <Container maxWidth="lg">
        {/* <div className={`${styled.noteWrapper}`}>
          <Typography
            sx={{
              fontSize: {
                md: 18,
                xs: 10,
              },
              letterSpacing: {
                md: "3px",
                xs: 0,
              },
              color: "rgb(255, 6, 6)",
              textDecoration: "none",
              marginTop: "80px",
              textAlign: "center",
              width: "100%",
              paddingBottom: "15px",
              fontWeight: "normal",
            }}
          >
            ※当日のデータは、翌AM6時間に更新されます。
          </Typography>
        </div> */}
        <Typography
          sx={{
            fontSize: "28px",
            color: "#fff",
            top: "104px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            margin: "80px 0 16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid white",
            textAlign: "left",
          }}
        >
          日報
        </Typography>
      </Container>

      <Container
        // maxWidth="xl"
        sx={{
          padding: "0px !important",
        }}
      >
        <Box
          sx={{
            marginTop: "40px",
            paddingRight: "16px",
            paddingLeft: "16px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <Button
                variant="outlined"
                onClick={() =>
                  setDateSelected((prev) => prev.subtract(1, "day"))
                }
              >
                前日
              </Button>
            </Grid>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <FormControl>
                <DatePickerComponent
                  currentDate={dateSelected}
                  onDateChange={setDateSelected}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <Button
                variant="outlined"
                onClick={() => setDateSelected((prev) => prev.add(1, "day"))}
              >
                翌日
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end", gap: "16px" }}
            >
              <Button
                variant="contained"
                type="button"
                color="primary"
                startIcon={
                  <img src={iconDownload} alt="iconDownload" width="16" />
                }
                style={{ fontWeight: "600", backgroundColor: "#000" }}
                onClick={handleExportCSV}
                disabled={isLoadingExport}
              >
                CSVダウンロード
              </Button>
              <Button
                variant="contained"
                type="button"
                color="primary"
                startIcon={<img src={iconRedDelete} alt="delete" width="16" />}
                style={{
                  fontWeight: "600",
                  backgroundColor: "white",
                  color: "rgba(211, 47, 47, 1)",
                  border: "1px solid rgba(211, 47, 47, 1)",
                }}
                onClick={handleSelectionDelete}
              >
                選択削除
              </Button>
              <Button
                variant="contained"
                type="button"
                color="primary"
                startIcon={
                  <img src={iconWhiteDelete} alt="delete" width="16" />
                }
                style={{
                  fontWeight: "600",
                  backgroundColor: "rgba(211, 47, 47, 1)",
                  color: "white",
                  border: "1px solid rgba(211, 47, 47, 1)",
                }}
                onClick={handleBulkDelete}
              >
                一括削除
              </Button>
            </Grid>
          </Grid>
        </Box>
        <MonthlyReverseCountComponent
          days={daysInMonth}
          currentDate={dateSelected}
          updateCurrentDate={updateCurrentDate}
          activeDayIndex={activeDayIndex}
          setActiveDayIndex={setActiveDayIndex}
          type={"dailyReport"}
        />
        <Box sx={{ margin: "40px 0" }}>
          <Paper
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              color: "rgba(0, 0, 0, 0.87)",
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              borderRadius: "4px",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
              width: "100%",
              marginBottom: "16px",
              overflow: "auto",
            }}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={styleTableHead}>　</TableCell>
                    {dailyReportFields.map((field, index) => {
                      if (field === "ID") {
                        return null;
                      } else if (field === "日付" || field === "担当者") {
                        const isSorted = activeSortColumn === index;
                        const sortDirection = isSorted
                          ? sortColumnDirection
                          : "asc";

                        return (
                          <TableCell
                            key={index}
                            align="center"
                            sx={styleTableHead}
                            sortDirection={isSorted ? sortDirection : false}
                          >
                            <TableSortLabel
                              active={isSorted}
                              direction={sortDirection}
                              onClick={() => handleSort(index)}
                              sx={styleTableSort}
                            >
                              {field}
                            </TableSortLabel>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell sx={styleTableHead}>{field}</TableCell>
                        );
                      }
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dailyReportList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell sx={styleTableBody}>
                        <Checkbox
                          style={{ padding: "0" }}
                          key={item[0]}
                          checked={orderSelectedDelete.some(
                            (e) => e[0] === item[0]
                          )}
                          onClick={() => {
                            if (
                              orderSelectedDelete.some((e) => e[0] === item[0])
                            ) {
                              setOrderSelectedDelete(
                                orderSelectedDelete.filter(
                                  (e) => e[0] !== item[0]
                                )
                              );
                            } else {
                              setOrderSelectedDelete([
                                ...orderSelectedDelete,
                                item,
                              ]);
                            }
                          }}
                        />
                      </TableCell>
                      {item.map(
                        (value, index) =>
                          index !== 0 && (
                            <TableCell key={index} sx={styleTableBody}>
                              {value.split("\n").map((line, index) => (
                                <div key={index} style={{ color: line.includes('_is_by_card') ? 'red' : 'unset' }}>
                                  {line.replace('_is_by_card', '')}
                                </div>
                              ))}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  ))}
                  {dailyReportList.length === 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={dailyReportFields.length}
                        align="center"
                      >
                        <Typography>該当データがありません。</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={perPage}
              total={total}
              page={page || 0}
              rowsPerPageOptions={[10]}
              onPageChange={changePage}
              handleChangeRowsPerPage={changePerPage}
            />
          </Paper>
        </Box>
      </Container>

      {/* <Container maxWidth="lg">
        <Typography
          sx={{
            fontSize: "28px",
            color: "#fff",
            top: "104px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            margin: "80px 0 16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid white",
            textAlign: "left",
          }}
        >
          セラピスト集計
        </Typography>
      </Container> */}

      {/* <Container
        sx={{
          padding: "0px !important",
        }}
      >
        <Box sx={{ margin: "40px 0" }}>
          <Paper
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              color: "rgba(0, 0, 0, 0.87)",
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              borderRadius: "4px",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
              width: "100%",
              marginBottom: "16px",
              overflow: "auto",
            }}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {dailyReportTherapist1Fields.map((field) => (
                      <TableCell key={field.value} sx={styleTableHead}>
                        {field.label}
                      </TableCell>
                    ))}

                    {dataNominatation.map((item) => (
                      <TableCell key={item.id} sx={styleTableHead}>
                        {item.name}
                      </TableCell>
                    ))}

                    {dailyReportTherapist2Fields.map((field) => (
                      <TableCell key={field.value} sx={styleTableHead}>
                        {field.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dailyReportTherapistList.map((item) => (
                    <TableRow key={item.store_name + item.therapist_name}>
                      {dailyReportTherapist1Fields.map((field) => (
                        <TableCell key={field.value} sx={styleTableBody}>
                          {field.type === "boolean" &&
                            getValueBoolean(item[field.value])}
                          {field.unit
                            ? formatNumberWithUnit(
                                item[field.value],
                                field.unit
                              )
                            : item[field.value]}
                        </TableCell>
                      ))}

                      {dataNominatation.map((itemNomination) => (
                        <TableCell key={item.id} sx={styleTableBody}>
                          {getCountOrderNominatation(
                            item.nominations || [],
                            itemNomination.id
                          )}
                        </TableCell>
                      ))}

                      {dailyReportTherapist2Fields.map((field) => (
                        <TableCell key={field.value} sx={styleTableBody}>
                          {field.type === "boolean" &&
                            getValueBoolean(item[field.value])}
                          {field.unit
                            ? formatNumberWithUnit(
                                item[field.value],
                                field.unit
                              )
                            : item[field.value]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  {dailyReportTherapistList.length === 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={
                          dailyReportTherapist1Fields.length +
                          dataNominatation.length +
                          dailyReportTherapist2Fields.length
                        }
                        align="center"
                      >
                        <Typography>該当データがありません。</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={perPageTherapist}
              total={totalTherapist}
              page={pageTherapist}
              rowsPerPageOptions={[10]}
              onPageChange={changePageTherapist}
              handleChangeRowsPerPage={changePerPageTherapist}
            />
          </Paper>
        </Box>
      </Container> */}
    </>
  );
};

export default DailyReport;
