import { useState } from "react";
import { login } from "../../../api/authentication.js";
import { setToken, setUser } from "../../../api/axios.js";
import { useNavigate } from "react-router-dom";

const useLogin = () => {
  const navigate = useNavigate();
  const [ID, setID] = useState("");
  const [password, setPassword] = useState("");
  const [idError, setIdError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const IDRegex =
    /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
  const passwordRegex = /^[a-zA-Z0-9.?/-]{6,24}$/;

  const handleBlur = (type) => {
    if (type === "ID") {
      if (!IDRegex.test(ID)) {
        setIdError("メールアドレスの形式が不正です。");
      } else {
        setIdError("");
      }
    } else if (type === "password") {
      if (!passwordRegex.test(password)) {
        setPasswordError("パスワードの形式が不正です。");
      } else {
        setPasswordError("");
      }
    }
  };

  const handleSubmitLogin = async () => {
    try {
      if (!ID || !password) {
        setIdError("メールアドレスの形式が不正です。");
        setPasswordError("パスワードの形式が不正です。");
        return;
      } else {
        const requestBody = {
          email: ID,
          password: password,
          remember: true,
        };
        try {
          const data = await login(requestBody);
          if (data.user && data.access_token) {
            setToken(data.access_token);
            setUser(data.user);
            navigate("/staff_schedule");
          } else {
            alert("エラーが発生しました。");
          }
        } catch (error) {
          if (error?.response?.status === 400) {
            setIdError("メールアドレスまたはパスワードが不正です。");
            setPasswordError("メールアドレスまたはパスワードが不正です。");
          } else if (error?.response?.status === 401) {
            alert("メールアドレスまたはパスワードが不正です。");
          } else {
            alert("エラーが発生しました。");
          }
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleNavigateStaffSchedule = () => {
    navigate("/staff_schedule");
  };

  return {
    ID,
    setID,
    password,
    setPassword,
    idError,
    passwordError,
    handleBlur,
    handleSubmitLogin,
    handleNavigateStaffSchedule,
  };
};

export default useLogin;
