import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/ja";
import "moment-timezone";
import "./index.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box, Button, Checkbox, Dialog, DialogTitle, Modal, Typography } from "@mui/material";
import PopupEventComponent from "../PopupEvent";
import dayjs from "dayjs";
import { useQuery, useQueryClient } from "react-query";
import { deleteMultipleStaffSchedule, getListStaffSchedule } from "../../api/staffSchedule";
import { STALE_TIME_DEFAULT } from "../../constants";
import { iconRedDelete, iconWhiteDelete } from "../../assets";

moment.locale("ja");

const localizer = momentLocalizer(moment);

const styleEvent = (event) => {
  return {
    backgroundColor: event?.detail?.is_all_day ? (event.bgColor || "#3174ad") : "white",
    color: event?.detail?.is_all_day ? "white" : "black",
    borderColor: event.bgColor || "#3174ad",
    borderWidth: event?.detail?.is_all_day ? "0px" : "1px 1px 1px 7px",
    borderStyle: event?.detail?.is_all_day ? "none" : "solid",
    borderRadius: "3px",
    opacity: 1,
    margin: "0 0 2px",
    padding: "0 4px",
    cursor: "pointer",
    width: "100%",
  };
};

const eventPropGetter = (event, start, end, isSelected) => {
  return {
    style: styleEvent(event),
  }
};

const calendarStyle = (date) => {
  const currentDate = `${new Date().getDate()} ${new Date().getMonth() + 1
    } ${new Date().getFullYear()}`;
  const allDate = `${date.getDate()} ${date.getMonth() + 1
    } ${date.getFullYear()}`;

  if (allDate === currentDate) {
    return {
      style: {
        backgroundColor: "#d6fad5",
      },
    };
  }

  if (date.getDay() === 0) {
    return {
      style: {
        backgroundColor: "#f8e0e0",
      },
    };
  }

  if (date.getDay() === 6) {
    return {
      style: {
        backgroundColor: "#ceecf5",
      },
    };
  }

  return {};
};

const formats = {
  monthHeaderFormat: "YYYY年 MM月",
  weekdayFormat: (date, culture, localizer) =>
    localizer.format(date, "dddd", culture),
  timeGutterFormat: (date, culture, localizer) =>
    localizer.format(date, "HH:mm", culture),
  agendaTimeFormat: (date, culture, localizer) =>
    localizer.format(date, "HH:mm", culture),
  eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
    localizer.format(start, "HH:mm", culture) +
    " - " +
    localizer.format(end, "HH:mm", culture),
  agendaHeaderFormat: ({ start, end }, culture, localizer) =>
    localizer.format(start, "YYYY/MM/DD", culture) +
    " - " +
    localizer.format(end, "YYYY/MM/DD", culture),
  agendaDateFormat: (date, culture, localizer) =>
    localizer.format(date, "MM月DD日", culture),
  agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
    localizer.format(start, "HH:mm", culture) +
    " - " +
    localizer.format(end, "HH:mm", culture),
  dayHeaderFormat: (date, culture, localizer) =>
    localizer.format(date, "MM月 DD日(ddd)", culture),
  dayFormat: (date, culture, localizer) =>
    localizer.format(date, "D日(ddd)", culture),
  dateFormat: (date, culture, localizer) =>
    localizer.format(date, "DD日", culture),
};

const CustomEventWrapper = ({ children }) => <div>{children}</div>;

function CalendarComponent() {
  const queryClient = useQueryClient();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [popups, setPopups] = useState([]);
  const [detailModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [currentView, setCurrentView] = useState("month");
  const [startDate, setStartDate] = useState(dayjs().startOf("month").startOf("week"));
  const [endDate, setEndDate] = useState(dayjs().endOf("month").endOf("week"));
  const [selectableMonth] = useState(true);

  const [scheduleList, setScheduleList] = useState([]);
  const [groupSchedule, setGroupSchedule] = useState([]);
  const [scheduleSelected, setScheduleSelected] = useState();
  const [dateSelected, setDateSelected] = useState(null);

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [scheduleSelectedDelete, setScheduleSelectedDelete] = useState([]);

  const CustomEvent = useMemo(() => {
    return ({ event }) =>
      <Box className="event-label">
        <Checkbox
          className="event-checkbox"
          key={event.id}
          checked={scheduleSelectedDelete.some((e) => e.id === event.id)}
          onClick={() => {
            if (scheduleSelectedDelete.some((e) => e.id === event.id)) {
              setScheduleSelectedDelete(scheduleSelectedDelete.filter((e) => e.id !== event.id));
            } else {
              setScheduleSelectedDelete([...scheduleSelectedDelete, event]);
            }
          }}
        />
        <span>{event.title}</span>
      </Box>
  }, [scheduleSelectedDelete]);

  const handleDataSchedule = (data) => {
    const result = Object.values(data)
      .flat()
      .map((item) => {
        return {
          id: item.id,
          title: item.title,
          start: dayjs(item.start_time).toDate(),
          end: dayjs(item.end_time).toDate(),
          bgColor: item.genre?.color,
          color: "#fff",
          detail: item,
        };
      });

    setScheduleList(result);
  };

  const { data: dataStaffSchedule, refetch: refetchStaffSchedule } = useQuery(
    ["list_all_staff_schedule", startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD")],
    () => {
      return getListStaffSchedule({
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
      });
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME_DEFAULT,
    }
  );

  useEffect(() => {
    try {
      if (dataStaffSchedule) {
        handleDataSchedule(dataStaffSchedule.data);
      }
    } catch (error) {
      alert("Fetch data error!");
    }
  }, [dataStaffSchedule]);

  const handleSelectEvent = useCallback(
    (event, e) => {
      // check if click on checkbox
      if (e.target.tagName === "INPUT") {
        return;
      }

      if (currentView === "day" || currentView === "week") {
        setScheduleSelected(event);
        setDetailModalIsOpen(true);
      } else {
        const group = scheduleList.filter(
          (e) =>
            e.start.toDateString() === event.start.toDateString() ||
            e.end.toDateString() === event.end.toDateString()
        );
        setGroupSchedule(group);
        setDateSelected(event.start);
        setModalIsOpen(true);
      }
    },
    [currentView, scheduleList]
  );

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSelectSlot = useCallback(
    ({ start, end, slotInfo }) => {
      console.log('slotInfo', slotInfo);
      
      if (selectableMonth) {
        setDateSelected(start);
        setScheduleSelected(undefined);
        setDetailModalIsOpen(true);
      } else {
        const newPopups = [];
        for (let i = 0; i < 10; i++) {
          newPopups.push({ id: i, message: `Popup ${i + 1}` });
        }
        setPopups(newPopups);
      }
    },
    [selectableMonth]
  );

  const handleEventClick = (event) => {
    setScheduleSelected(event);
    setDetailModalIsOpen(true);
    setModalIsOpen(false);
  };

  const closeDetailModal = () => {
    setDetailModalIsOpen(false);
    setScheduleSelected(undefined);
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  const handleSelectionDelete = useCallback(async () => {
    if (isLoadingDelete) return;
    if (scheduleSelectedDelete.length === 0) {
      alert("選択されたスケジュールがありません。");
      return;
    } else {
      // show dialog confirm delete
      let alertConfirm = "選択されたスケジュールを削除しますか？";
      scheduleSelectedDelete.forEach((event) => {
        const start = dayjs(event.start).format("YYYY年MM月DD日 HH:mm");
        const end = dayjs(event.end).format("YYYY年MM月DD日 HH:mm");
        alertConfirm += `\n${start} - ${end}: ${event.title}`;
      });
      if (window.confirm(alertConfirm)) {
        setIsLoadingDelete(true);
        const ids = scheduleSelectedDelete.map((e) => e.id);
        const params = { ids: ids };
        try {
          const response = await deleteMultipleStaffSchedule(params);
          if (response.success) {
            queryClient.invalidateQueries("list_all_staff_schedule");
            await refetchStaffSchedule();
            setScheduleSelectedDelete([]);
            alert("削除しました。");
          } else {
            alert("削除に失敗しました。");
          }
        } catch (error) {
          alert("削除に失敗しました。");
        } finally {
          setIsLoadingDelete(false);
        }
      }
    }
  }, [scheduleSelectedDelete, isLoadingDelete, refetchStaffSchedule, queryClient]);

  const handleBulkDelete = useCallback(async () => {
    if (isLoadingDelete) return;
    const start_date = dayjs(startDate).format("YYYY-MM-DD");
    const end_date = dayjs(endDate).format("YYYY-MM-DD");
    // show dialog confirm delete all with date range: Do you want to delete all schedules from ... to ...?
    if (window.confirm(dayjs(startDate).format("YYYY年MM月DD日") + "から" + dayjs(endDate).format("YYYY年MM月DD日") + "までのスケジュールを削除しますか？")) {
      setIsLoadingDelete(true);
      const params = { start_date, end_date };
      try {
        const response = await deleteMultipleStaffSchedule(params);
        if (response.success) {
          queryClient.invalidateQueries("list_all_staff_schedule");
          await refetchStaffSchedule();
          setScheduleSelectedDelete([]);
          alert("削除しました。");
        } else {
          alert("削除に失敗しました。");
        }
      } catch (error) {
        alert("削除に失敗しました。");
      } finally {
        setIsLoadingDelete(false);
      }
    }
  }, [isLoadingDelete, startDate, endDate, refetchStaffSchedule, queryClient]);

  return (
    <>
      <Box style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "18px",
        marginBottom: "16px"
      }}>
        <Button
          variant="contained"
          type="button"
          color="primary"
          startIcon={
            <img src={iconRedDelete} alt="delete" width={14} height={18} />
          }
          style={{
            fontWeight: "600",
            backgroundColor: "white",
            color: "rgba(211, 47, 47, 1)",
            fontSize: "0.8rem",
            height: "28px",
            border: "1px solid rgba(211, 47, 47, 1)"
          }}
          onClick={handleSelectionDelete}
        >
          選択削除
        </Button>
        <Button
          variant="contained"
          type="button"
          color="primary"
          startIcon={
            <img src={iconWhiteDelete} alt="delete" width={14} height={18} />
          }
          style={{
            fontWeight: "600",
            backgroundColor: "rgba(211, 47, 47, 1)",
            color: "white",
            fontSize: "0.8rem",
            height: "28px",
            border: "1px solid rgba(211, 47, 47, 1)"
          }}
          onClick={handleBulkDelete}
        >
          一括削除
        </Button>
      </Box>
      <Calendar
        localizer={localizer}
        events={scheduleList}
        startAccessor="start"
        endAccessor="end"
        defaultView="month"
        messages={{
          today: "今日",
          previous: "前",
          next: "次",
          month: "月別",
          week: "週別",
          day: "日別",
          agenda: "リスト",
          showMore: (total) => `+${total}`,
        }}
        onView={handleViewChange}
        components={{
          event: CustomEvent,
          eventWrapper: CustomEventWrapper,
        }}
        onRangeChange={(range, view) => {
          if (view === "month" || !view) {
            // const startWeek = dayjs(range.start).endOf("week").format("YYYY-MM");
            // setStartDate(dayjs(startWeek).startOf("month"));
            // setEndDate(dayjs(startWeek).endOf("month"));
            setStartDate(dayjs(range.start));
            setEndDate(dayjs(range.end));
          } else if (range.start && range.end && view === "agenda") {
            setStartDate(dayjs(range.start));
            setEndDate(dayjs(range.end));
          } else {
            setStartDate(dayjs(range[0]));
            setEndDate(dayjs(range[range.length - 1]));
          }
        }}
        formats={formats}
        dayPropGetter={calendarStyle}
        eventPropGetter={eventPropGetter}
        popup
        selectable={selectableMonth}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
      />
      {popups.map((popup) => (
        <div
          key={popup.id}
          style={{
            position: "absolute",
            top: 20 + popup.id * 30,
            left: 20,
            padding: 10,
            background: "white",
            border: "1px solid black",
          }}
        >
          {popup.message}
        </div>
      ))}
      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="event-details-title"
      >
        <DialogTitle id="event-details-title">
          <Typography align="center">
            {dateSelected ? dayjs(dateSelected).format("YYYY年MM月DD日") : ""}
          </Typography>
        </DialogTitle>

        <Box sx={{
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          minWidth: '200px',
          gap: '10px'
        }}>
          {groupSchedule.map((event) => (
            <Box style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}>
              <Checkbox
                style={{ padding: "0", height: "14px" }}
                key={event.id}
                checked={scheduleSelectedDelete.some((e) => e.id === event.id)}
                onClick={() => {
                  if (scheduleSelectedDelete.some((e) => e.id === event.id)) {
                    setScheduleSelectedDelete(scheduleSelectedDelete.filter((e) => e.id !== event.id));
                  } else {
                    setScheduleSelectedDelete([...scheduleSelectedDelete, event]);
                  }
                }}
              />
              <Typography
                key={event.id}
                style={styleEvent(event)}
                onClick={() => handleEventClick(event)}
              >
                {event.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Dialog>
      <Modal
        open={detailModalIsOpen}
        onClose={closeDetailModal}
        aria-labelledby="transition-modal-title"
      >
        <PopupEventComponent
          handleClose={closeDetailModal}
          item={scheduleSelected}
          dateSelected={dateSelected}
          refetchStaffSchedule={() => {
            queryClient.invalidateQueries("list_all_staff_schedule");
            refetchStaffSchedule();
          }}
        />
      </Modal>
    </>
  );
}

export default CalendarComponent;
